.navbar-dark .navbar-nav .nav-link {
  color: white;

  &:hover,
  &:focus {
    color: var(--graydark1);
    svg {
      path,
      line {
        stroke: var(--graydark1);
      }
    }
  }
  // @include media-breakpoint-down(xl) {
  padding-left: 0rem;
  padding-right: 0rem;
  margin-left: 0.5rem;
  // }
  // @include media-breakpoint-up(xxl) {
  //   padding-left: 0.5rem;
  //   padding-right: 0.5rem;
  // }
}
.navbar-dark .navbar-nav {
  .nav-item {
    white-space: nowrap;
    font-weight: 500;
    @include large-text(18px, 21px, font-size);
  }
}

.text-info .nav-link.nav-item {
  color: var(--info);
}

.navbar {
  position: sticky;
  top: 0;
  z-index: 1001;
}
.navbar-expand {
  box-shadow: $box-shadow;
  height: 52px;

  justify-content: flex-end;
}

.nav-item .logo {
  max-width: 223px;
}

.nav-line {
  background-color: var(--graydark1);
  width: 2px;
}

.navbar-smart {
  height: 48px;
  .nav-item .logo {
    width: 10rem;
  }
  .nav-item {
    list-style: none;
  }
}

.sb-avatar__text span {
  padding-top: 7%;
}

.patient-view-nav {
  min-height: 71px;
  align-items: center;
  background-color: #ffffff;

  margin-bottom: 1.6rem;
  .nav-link {
    @include make-accessible(var(--dark), var(--dark-dark), color);
    padding: 0px;
    @include large-text(18px, 21px, font-size);
    @include media-breakpoint-down(sm) {
      font-size: 1rem;
    }

    border-bottom: 2px solid transparent;
    transition: border-color 0.2s ease-out;
    &:focus {
      @include make-accessible(
        var(--primary),
        var(--dark-primary) !important,
        color
      );
    }
    &.active {
      @include make-accessible(
        var(--primary),
        var(--dark-primary) !important,
        color
      );
    }
  }
  .nav-link.btn-link:hover,
  .nav-link.btn-link:focus,
  .nav-link.btn-link:active {
    border: 2px solid transparent !important;
  }
  .nav-link:hover,
  .table-nav-headers .nav-link:hover,
  .nav-tabs .nav-link:focus,
  .table-nav-headers .nav-link:focus {
    @include make-accessible(var(--primary), var(--primary), color);
  }
  .nav-link.active {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      // z-index: 1;
      left: 0;
      right: 0;
      bottom: -0.25rem;
      height: 0;
      @include make-accessible(
        1px solid var(--primary),
        1px solid var(--dark-primary) !important,
        border
      );
      @include make-accessible(
        var(--primary),
        var(--dark-primary) !important,
        background
      );
      border-radius: 5px;
    }
  }
  .nav-item {
    padding: 1rem 35px;
    @include media-breakpoint-down(mdlg) {
      padding: 1rem;
    }
  }
}
.navbar-light .navbar-nav .nav-link {
  color: $dark;
  &.active {
    @include make-accessible(var(--primary), var(--dark-primary), color);
  }
}
.active-bottom-border {
  .nav-item.nav-link {
    @include make-accessible(var(--primary), var(--dark-primary), color);
  }
  position: relative;
  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    bottom: -0.3rem;
    border-radius: 5px;
    height: 0;
    @include make-accessible(
      2px solid var(--primary),
      2px solid var(--dark-primary) !important,
      border
    );
    @include make-accessible(
      var(--primary),
      var(--dark-primary),
      background-color
    );
    margin-left: 1rem;
    margin-right: 1rem;
  }
  &.white {
    color: white;
    .nav-item.nav-link {
      @include make-accessible(var(--white), var(--white), color);
    }

    &:after {
      @include make-accessible(
        2px solid var(--white),
        2px solid var(--white) !important,
        border
      );
      bottom: -0.15rem;
      @include make-accessible(var(--white), var(--white), background-color);
    }
  }
}

.nav-box1,
.nav-box2 {
  flex-wrap: nowrap;
  overflow: hidden;
}

.nav-width1,
.nav-width2 {
  white-space: nowrap;
}

.nav-tabs .nav-link.tab.active {
  background-color: var(--primary);
  color: white;
  border-radius: 3px;
}

.nav-link.nav-tab.active {
  color: var(--primary);
  background-color: transparent;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    bottom: -0.3rem;
    border-radius: 5px;
    height: 0;
    border: 2px solid var(--primary);
    background: var(--primary);
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.nav-pills .nav-link.active.light-blue {
  background-color: rgba(var(--rgb-primary), 0.15);
  color: var(--primary);
}

.back-to-patient-link.active {
  display: none;
}

.nav-pills.bg-dark {
  border: 3px solid var(--dark);

  a.nav-link {
    transition: color 0.5s ease, background-color 0.5s ease;
    color: white;
    background-color: var(--dark);
    &.active {
      background-color: var(--white);
      color: var(--dark);
    }
  }
  .nav-item {
    width: 8rem;
    text-align: center;
  }
}
