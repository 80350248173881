.accessibility.form-group {
  font-size: 1rem;

  .large {
    font-size: 1.125rem;
  }
}

.control-label {
  font-weight: 500;
  margin-bottom: 0.25rem;
}

input {
  line-height: normal;
}

.form-control {
  border: 2px solid var(--graydark1);
  @include make-accessible(var(--dark), var(--dark-dark), color);
  border-radius: $border-radius;
  // font-weight: 500;
  @include large-text(calc(2.25rem + 4px), calc(2.25rem + 8px), height);
  // height: calc(1.5em + 0.75rem + 4px);

  &:focus {
    box-shadow: none;
  }
}

.form-control input {
  // don't show input styles on the input inside the form-control
  border: none;
  background: none;
  padding: 0.375rem 1.5rem 0.375rem 0.75rem;
  margin: 0;
  // background-image: url("../../images/chevron_down_small.svg");
  // background-repeat: no-repeat;
  // background-position: right 0.75rem top 50%;
  // background-size: 12px 12px;
  width: 100%;
  cursor: pointer;
  // &:hover {
  //   background-image: url("../../images/chevron_down_small_hover.svg");
  // }
  &:focus-visible {
    box-shadow: none;
  }
}

.form-group,
.form {
  input:focus-visible {
    box-shadow: none;
  }
}

.card,
.modal-body {
  .form-row .primary-underline::after {
    border: 1px solid var(--graydark1) !important;
    background: var(--graydark1) !important;
  }

  .form-row .primary-underline:focus-within {
    &:after {
      border: 1px solid var(--primary) !important;
      background: var(--primary) !important;
    }
  }
}

.card .form-control.date-time,
.modal-body .form-control.date-time {
  background-color: white;
  border: 0;
  padding: 0;
  position: relative;

  &:focus,
  &:active {
    background-color: white;
    border: 0;
    padding: 0;
    position: relative;
  }
}

.input-group > .form-control:not(:last-child) {
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}

textarea.form-control {
  @include make-accessible(var(--dark), var(--dark-dark), color);
  line-height: 1.4;

  &:focus {
    @include make-accessible(var(--dark), var(--dark-dark), color);
  }
}

textarea {
  overflow-y: auto;
  background-color: var(--light);
  border-radius: $border-radius;
  resize: none;

  &:disabled {
    background-color: var(--light);
  }
}

.was-validated .form-control:valid {
  background-image: none;
}

input:disabled:not[autocomplete="list"] {
  background-color: white !important;

  &.grey-background {
    background-color: $gray !important;
  }

  padding-right: 2rem;
  border: 2px solid var(--graydark2);
  background-image: url("../../images/googlelock.svg");
  background-position: right 0.6rem top 50%;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
}

input.form-control:disabled,
#dateOfBirth.readonly {
  background-color: var(--bg);
  border: 2px solid var(--graydark2);
  background-image: url("../../images/googlelock.svg");
  background-position: right 0.6rem top 50%;
  background-size: 18px 18px;
  background-repeat: no-repeat;
}

.was-validated .form-control:valid,
.was-validated .filter-selector:valid,
.form-control.is-valid,
.is-valid.filter-selector {
  border-color: transparent;
}

.invalid-feedback {
  display: none !important;
}

.invalid-feedback.show {
  display: block !important;
}

// .is-invalid.filter-selector .was-validated .form-control:invalid,
// .was-validated .filter-selector:invalid,
.form-control.is-invalid {
  @include make-accessible(var(--danger), var(--dark-danger), border-color);
  @include make-accessible(
    url("../../images/result.svg") !important,
    url("../../images/result-dark.svg") !important,
    background-image
  );

  &:focus {
    background-image: none !important;
    padding-left: 0.75rem !important;
  }
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  background-image: none;
  // padding-left: 1rem;
  border-color: var(--graydark1);
}

input[name="password"].is-invalid,
input[name="newPassword"].is-invalid,
input[name="confirmPassword"].is-invalid {
  padding-left: calc(1.5em + 0.75rem) !important;

  background-repeat: no-repeat;
  background-position: left calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

textarea.is-invalid {
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.875em + 0.1875rem) !important;
}

.search-input {
  background-image: url("../../images/glyph-search.png");
  background-repeat: no-repeat;
  background-position: right 0.75rem top 50%;

  @include media-breakpoint-down(sm) {
    padding: 1rem !important;
  }

  //   background-color: white !important;
  //   border: none;
  &.left-search {
    background-position: left 0.75rem top 50%;
    padding-left: 2rem;
  }
}

.bg-grey {
  background-color: #f8f8f8 !important;
}

label {
  margin-bottom: 0.25rem;
  font-weight: 500;
}

.font-weight-normal label {
  font-weight: 400;
}

#group .form-control {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0.375rem 2rem 0.375rem 0.75rem;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.form-row {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.criteria-row {
  margin-right: -2.2rem !important;

  @media (min-width: 768px) and (max-width: 1280px) {
    margin-right: -4.2rem !important;
  }
}

.neg-margin-top {
  margin-top: -0.1rem;
}

.form-control-sm {
  padding: 0.25rem 0.75rem;
  @include large-text(1rem, 1.125rem, font-size);

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

::placeholder {
  color: var(--secondary);
  font-weight: 400;
}

input::-webkit-input-placeholder {
  line-height: normal !important;
}

:-ms-input-placeholder.form-control {
  color: var(--secondary);
  font-weight: 400;
}

:-ms-input-placeholder {
  color: var(--secondary) !important;
}

input::-webkit-contacts-auto-fill-button,
input[type="password"]::-webkit-credentials-auto-fill-button {
  visibility: hidden;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    input:disabled {
      color: black !important;
    }

    .react-datepicker-wrapper input,
    input[name="phoneNumber"] {
      padding: 1.3rem 0.75rem;
    }
  }
}

.form-control:focus {
  border-color: var(--extra-light-primary) !important;
}

input[type="range"] {
  -webkit-appearance: none;
  // margin-right: 15px;
  // width: 200px;
  height: 7px;
  background: var(--gray);
  border-radius: 5px;
  // background-image: linear-gradient(#ff4500, #ff4500);
  // background-size: 70% 100%;
  background-repeat: no-repeat;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: $dark;
  cursor: pointer;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

// Extending form-control class, then targeting e-input which is causing the problem with
// some importants to force it to have the correct borders

.form-control.custom-control {
  &:hover {
    border-color: var(--extra-light-primary) !important;
  }

  // &:focus {
  //   border-color: var(--extra-light-primary) !important;
  // }
  // &:active {
  //   border-color: var(--extra-light-primary) !important;
  // }
}

.bulk-invite-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0.375rem 2.5rem 0.375rem 0.75rem;
}

.select {
  :focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.form-control-no-border {
  border: none;

  @include large-text(1rem, 1.125rem, font-size);
  color: var(--primary);

  &.xs {
    @include large-text(1.2rem, 1.4rem, width);
  }

  &.small {
    @include large-text(1.7rem, 1.9rem, width);
  }

  &.med {
    @include large-text(2.1rem, 2.3rem, width);
  }
  &.large {
    @include large-text(2.7rem, 2.9rem, width);
  }
  &.xl {
    @include large-text(3.3rem, 3.5rem, width);
  }

  &.extra-small {
    width: 1.5rem;
  }

  &::placeholder {
    color: var(--primary);
  }

  &:disabled,
  &:focus {
    &::placeholder {
      color: $gray-dark1;
    }
  }
}

input[type="number"].form-control-no-border::-webkit-inner-spin-button,
input[type="number"].form-control-no-border::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"].form-control-no-border {
  -moz-appearance: textfield;
  appearance: textfield;
}

.select.invalid-input > div:nth-child(2) {
  @include make-accessible(var(--danger), var(--dark-danger), border-color);
}
