@mixin make-accessible(
  $default-value,
  $accessible-value,
  $css-property: color,
  $css-selector: &
) {
  @if ($css-selector == null) {
    @warn "No parent css element found";
  }
  #{$css-property}: $default-value;
  @at-root {
    .accessible-theme #{$css-selector} {
      #{$css-property}: $accessible-value;
    }
  }
}

@mixin large-text(
  $default-value,
  $accessible-value,
  $css-property: font-size,
  $css-selector: &
) {
  @if ($css-selector == null) {
    @warn "No parent css element found";
  }
  #{$css-property}: $default-value;
  @at-root {
    .large-font-theme #{$css-selector} {
      #{$css-property}: $accessible-value;
    }
  }
}

@mixin smart-theme(
  $default-value,
  $smart-value,
  $css-property: color,
  $css-selector: &
) {
  @if ($css-selector == null) {
    @warn "No parent css element found";
  }
  #{$css-property}: $default-value;
  @at-root {
    .smart-theme #{$css-selector} {
      #{$css-property}: $smart-value;
    }
  }
}
