//Site wide selecting text
:root {
  --dark: #1f2f36;
  --bg: #f2f2f7;
  --primary: #0078c8;
  --app-primary: #0078c8;
  --dark-primary: #083f6a;
  --rgb-primary: 0, 120, 200;
}

:root:has(.app-container.accessible-theme) {
  --dark: #0a1b22;
  --bg: #f8f8f8;
}

@font-face {
  font-family: "Effra";
  src: url("../../../public/fonts/Effra_A_Rg.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Effra";
  src: url("../../../public/fonts/Effra_A_Bd.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Effra";
  src: url("../../../public/fonts/Effra_Md.ttf") format("truetype");
  font-weight: 500; /* or use 'medium' if it is a medium weight */
}

$primary: var(--primary);
$dark-primary: var(--dark-primary);

::selection {
  @include make-accessible(var(--dark), var(--dark-dark), color);
  background: var(--extra-light-primary); /* WebKit/Blink Browsers */
}

::-moz-selection {
  @include make-accessible(var(--dark), var(--dark-dark), color);
  background: var(--extra-light-primary); /* Gecko Browsers */
}

// :focus {
//   outline: none !important;
//   box-shadow: none !important;
// }
:focus-visible {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  border-radius: $border-radius;
}

#filter-button:focus-visible {
  outline: none;
  box-shadow: none;
}
//Transitions
a {
  -webkit-transition: color 0.1s;
  -moz-transition: color 0.1s;
  -o-transition: color 0.1s;
  transition: color 0.1s;
}

.badge,
.btn {
  -webkit-transition: background-color 0.1s;
  -moz-transition: background-color 0.1s;
  -o-transition: background-color 0.1s;
  transition: background-color 0.1s;
  // border: 2px;
}
body,
html {
  height: 100%;
  margin: 0;
  padding: 0 !important;
  background-size: cover;
  @media all and (-ms-high-contrast: none) {
    overflow-x: hidden;
  }
}
path,
ellipse,
circle {
  -webkit-transition: stroke 0.1s;
  -moz-transition: stroke 0.1s;
  -o-transition: stroke 0.1s;
  transition: stroke 0.1s;
}
//Hide eye on password field in IE/Edge
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

//Rules for adding transition to opening pages on login screen
.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact !important;
    background-color: white !important;

    .h-100 {
      height: 100%;
      margin: 0 !important;
      padding: 0 !important;
      overflow: hidden;
    }
  }
  @-moz-document url-prefix() {
    .d-flex:not(.ie-flex),
    .card:not(.instructions-card),
    .primary-underline {
      display: block !important;
    }
    tr {
      page-break-inside: avoid;
    }
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .d-flex:not(.ie-flex),
    .card:not(.instructions-card),
    .primary-underline {
      display: block !important;
    }
    tr {
      page-break-inside: avoid;
    }
  }
  .page-break {
    break-after: always;
    page-break-after: always !important; /* or page-break-before: always; */
  }

  .hide-on-print {
    display: none;
  }
  .force-page-break {
    display: block;
    page-break-after: always;
  }
  table:not(.table-condensed):not(.cerner):not(#modal-risk-profiles):not(
      .e-timeline-header-table-container
    ):not(.fc-scrollgrid):not(#modal-ehr-search)
    thead
    tr
    th:not(.fc-day):not(.fc-timegrid-axis) {
    background-color: #f8f8f8 !important;
    -webkit-print-color-adjust: exact;
  }
  .d-none {
    // show when printing
    display: block !important;
    // set text color to dark
    color: var(--dark) !important;
    .text-primary {
      color: var(--primary) !important;
    }
  }
}

.focusable-text {
  font-weight: normal !important;
  line-height: 1;
  cursor: default;
  &:focus {
    color: var(--graydark2) !important;
    svg path,
    svg rect {
      stroke: var(--graydark2);
    }
  }
}

// @media all {
//   .page-break {
//     display: none;
//   }
// }

@page {
  size: auto;
  margin: 20mm;
}

iframe {
  border: 0;
  overflow: visible;
}

.pt-6ish {
  padding-top: 2.25rem;
}

.vh-100-navbar {
  min-height: calc(100vh - 52px);
}

.gradient-background {
  background-size: cover;

  background-image: url("../../images/blue-gradient.png");
  background-color: #0078c8;
  @include media-breakpoint-up(lg) {
    margin-left: -2.25rem;
  }
}
#iframe-savings-card {
  visibility: hidden;
  position: absolute;
}
@media print {
  #iframe-savings-card {
    display: block;
    position: static;
    visibility: visible;
  }
}
