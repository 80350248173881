#print-leaflets {
    td {
        font-family: "Effra", sans-serif !important;
    }
    td.title {
        text-align: left;
        @extend h3;

    }
    td.center {
        text-align: left;
    }
    td.para  {
       font-size: 1rem;
       padding-bottom: .5rem;
    }

    td.bullet {
       width: 1px;
       padding-right: .25rem;
    }
    strong {
        padding-bottom: 1rem;
    }
    td.list {
        width: 40px;
    }
  
    table:last-of-type, table:nth-last-of-type(2) {
     
        .bullet {
            display: none;
        }
    }
}