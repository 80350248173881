.page-header {
  padding-top: 0.5rem;
  // &.margin-bottom {
  margin-bottom: 1rem;
  // }
  @include media-breakpoint-down(mdlg) {
    margin-left: 1rem;
    margin-right: 1rem;
    // margin-bottom: 1.5rem;
    padding-top: 1.5rem;
  }
  h3 {
    // margin-bottom: 0.75rem;

    &.primary-underline {
      position: relative;
      display: inline-block;
      z-index: 0;

      &:after {
        content: "";
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        bottom: 0;
        height: 0;
        background: var(--graydark1);
        border: 1px solid var(--graydark1);
        border-radius: 5px;
      }
    }
  }
}
