//Modals
.modal {
  // top: -1rem;
  overflow-wrap: anywhere;
  // padding-bottom: 2rem;
}

.center-modal {
  margin: 10% auto;
}

.modal-title {
  @include make-accessible(var(--dark), var(--dark-dark), color);
  @include large-text(1rem, 1.125rem, font-size);
}

.modal-header .close {
  padding: 0rem 0.5rem;
  margin: -0.5rem 0rem 0rem;
}

.modal-header {
  // margin: 0rem 1rem 0rem;
  padding: 0.5rem 1rem 0.25rem;
  // @include media-breakpoint-down(mdlg) {
  //   padding: 1rem 17.8px;
  // }
}

.modal-body {
  padding: 1rem 1rem 0.25rem;
}

.modal-footer {
  padding: 1rem 1.5rem;
}

.modal input.form-control {
  background-color: var(--gray);
  @include make-accessible(var(--dark), var(--dark-dark), color);

  &:focus {
    background-color: var(--gray);
    @include make-accessible(var(--dark), var(--dark-dark), color);
    border-color: var(--extra-light-primary);
    padding-right: 1rem !important;
  }

  &:disabled {
    background-color: white;
    background-image: url("../../images/googlelock.svg");
    background-repeat: no-repeat;
    background-position: 98% 50%;
    border-color: var(--graydark2);
    &.dose-field {
      background-image: none;
      background-color: var(--graydark1);
      border-color: var(--graydark1);
    }
  }
}

.modal textarea.form-control {
  background-color: var(--gray);
  @include make-accessible(var(--dark), var(--dark-dark), color);

  &:disabled {
    background-color: var(--graydark1);
  }
}

.modal-dialog:not(.modal-md):not(.modal-large):not(.modal-xl):not(
    .modal-full-screen
  ) {
  max-height: 60vh;
  // @include media-breakpoint-(md) {
  //   top: 50px;
  // }
  max-width: 550px;
  margin: 10% auto;

  @include media-breakpoint-down(sm) {
    max-width: 390px;
  }

  &.add-meds-modal {
    max-width: 650px;
    @include media-breakpoint-down(sm) {
      max-width: 500px;
    }
  }
}

.mrn-modal {
  @include media-breakpoint-up(md) {
    max-width: 767px !important;
  }
  @include media-breakpoint-down(sm) {
    max-width: 100% !important;
  }
  tr th:first-of-type {
    width: 20%;
  }
}

.modal-body.no-padding {
  padding-left: 0;
  padding-right: 0;
}

.sidebar-modal {
  // left: 140px;
  // @media all and (-ms-high-contrast: none) {
  //   left: 148px;
  // }
  // @include media-breakpoint-down(xl) {
  //   // left: 25px;
  //   @media all and (-ms-high-contrast: none) {
  //     left: 34px;
  //   }
  // }
  @include media-breakpoint-up(lg) {
    left: 120px;
  }

  @include media-breakpoint-down(mdlg) {
    left: 0;
  }
}

.verification-codes {
  width: 255px;

  @include media-breakpoint-down(sm) {
    width: 171px;
  }
}

.modal-content {
  padding-bottom: 1rem;
  border: 0;
  box-shadow: 0 2px 1px rgba(191, 191, 191, 0.33);
  // max-width: 750px;
  // @include media-breakpoint-down(sm) {
  //   max-width: 390px;
  // }
}

.disabled.modal-header .close {
  display: none;
}

#review-sidebar {
  -webkit-transition: width 0.5s ease;
  -moz-transition: width 0.5s ease;
  -o-transition: width 0.5s ease;
  transition: width 0.5s ease;

  .toggle-button {
    display: none;
  }

  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  box-shadow: none;
  border: none;
  -webkit-backdrop-filter: blur(19px);
  backdrop-filter: blur(19px);
  background: white;
  // color: white;
  vertical-align: middle;
  line-height: 30px;
  height: calc(100vh);
  position: fixed;
  z-index: 800;
  right: 0px;
  // @include media-breakpoint-up(xxl) {
  //   top: 74px;
  // }
  // @include media-breakpoint-down(xl) {
  top: 52px;

  // }
  // @include media-breakpoint-only(md) {
  //   top: 82px;
  // }
  @media all and (-ms-high-contrast: none) {
    @include media-breakpoint-down(xl) {
      top: 0;
    }
  }

  &.top-padding {
    top: 47px;

    @include media-breakpoint-down(xl) {
      top: 0;
    }
  }

  width: 0px;

  & .text {
    opacity: 0;
  }

  &.smart-sidebar {
    top: 0;
  }
}

#review-sidebar.in {
  box-shadow: 0 2px 10px 0 rgba(31, 47, 54, 0.15);
  overflow: visible;
  width: 25.5%;

  @include media-breakpoint-only(xl) {
    width: 28%;
  }

  @include media-breakpoint-only(lg) {
    width: 40%;
  }

  @include media-breakpoint-only(mdlg) {
    width: 40%;
  }

  @include media-breakpoint-only(md) {
    width: 50%;
  }

  @include media-breakpoint-down(sm) {
    width: 90%;
  }

  & .text {
    opacity: 1;
    -webkit-transition: opacity 0.2s ease-in-out 0.45s;
    -moz-transition: opacity 0.2s ease-in-out 0.45s;
    -o-transition: opacity 0.2s ease-in-out 0.45s;
  }

  .toggle-button {
    display: block;
    width: 40px;
    height: 60px;
    border: 4px solid var(--gray);
    border-radius: 7px;
    position: absolute;
    background-color: white;
    background-image: url("../../images/chevron_right.png");
    background-size: 12px;
    background-position: center;
    background-repeat: no-repeat;
    left: -20px;
    top: 50%;
    z-index: 10;

    &:hover {
      cursor: pointer;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fade {
    transition: none;
    transform: none;
  }
}

.disabled.modal-header .close:hover {
  cursor: default;

  svg path {
    stroke: var(--dark);
  }
}

body.modal-open {
  padding-right: 0 !important;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

.scroll-modal {
  .modal-content {
    padding-bottom: 0;
  }

  .modal-body {
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.modal-large {
  max-width: 864px;

  .modal-content {
    padding-bottom: 0;
  }

  .modal-body {
    max-height: 85vh;
    overflow-y: auto;
  }
}

.modal-full-screen.modal-dialog {
  width: 100%;
  max-width: none;
  height: 100%;
  margin: 0;
  max-height: 100% !important;

  .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
    padding-bottom: 0;
  }

  .modal-header {
    border-bottom: 2px solid white;

    .modal-title {
      width: 100%;
      padding-left: 1rem;
    }
  }

  .modal-body {
    overflow-y: auto;
    background-color: $gray;
  }
}

.modal-dialog.bg-gray {
  .modal-content {
    background-color: $gray;
  }
}

.modal-header.center-title {
  .modal-title {
    text-align: center;
    width: 100%;
  }
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}
