$ph-bg: #fff !default;
$ph-color: #e2e2e2 !default;
$ph-border-radius: 2px !default;

$ph-gutter: 18px !default;
$ph-spacer: 16px !default;

$ph-avatar-border-radius: 50% !default;

.ph-item {

  &,
  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  position: relative;
  display: flex;
  flex-wrap: wrap;
  //   padding: $ph-gutter ($ph-gutter / 2) ($ph-gutter - $ph-spacer) ($ph-gutter / 2);
  overflow: hidden;
  margin-bottom: $ph-gutter;
  background-color: $ph-bg;
  border-bottom: 1px solid darken($ph-bg, 10%);
  border-radius: $ph-border-radius;

  &::before {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 1;
    width: 500%;
    margin-left: -250%;
    animation: phAnimation 0.8s linear infinite;
    background: linear-gradient(to right,
        rgba($ph-bg, 0) 46%,
        rgba($ph-bg, 0.35) 50%,
        rgba($ph-bg, 0) 54%) 50% 50%;
  }

  >* {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
    padding-right: ($ph-gutter / 2);
    padding-left: ($ph-gutter / 2);
  }
}
.ph-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ($ph-spacer / 2);

  div {
    height: 10px;
    margin-bottom: ($ph-spacer / 2);
    background-color: $ph-color;
  }

  .fifteenpx,
  &.fifteenpx div {
    height: 15px;
    margin-bottom: $ph-spacer;
  }

  .big,
  &.big div {
    height: 20px;
    margin-bottom: $ph-spacer;
  }

  .tall {
    height: 150px;
    margin-bottom: $ph-spacer;
  }

  .extra-tall {
    height: 180px;
    margin-bottom: $ph-spacer;
  }

  .extra-tall-plus {
    height: 250px;
    margin-bottom: $ph-spacer;
  }

  .extreme-tall {
    height: 300px;
    margin-bottom: $ph-spacer;
  }

  .medium {
    height: 30px;
    margin-bottom: $ph-spacer;
  }

  .medium-tall {
    height: 50px;
    margin-bottom: $ph-spacer;
  }

  .empty {
    background-color: rgba($ph-bg, 0);
  }
}

.ph-col-14 {
  flex: 0 0 14.6667%;
}

.ph-col-3 {
  flex: 0 0 percentage(3/48);
}

.ph-col-15 {
  flex: 0 0 percentage(3/24);
}

.ph-col-half {
  flex: 0 0 percentage(1/24);
}

.ph-col-quarter {
  flex: 0 0 percentage(1/48);
}

.ph-col-1 {
  flex: 0 0 percentage(1 / 12);
}

.ph-col-2 {
  flex: 0 0 percentage(2 / 12);
}

.ph-col-real3 {
  flex: 0 0 percentage(3/12);
}

.ph-col-4 {
  flex: 0 0 percentage(4 / 12);
}

.ph-col-5 {
  flex: 0 0 percentage(9 / 24);
}

.ph-col-6 {
  flex: 0 0 percentage(6 / 12);
}

.ph-col-8 {
  flex: 0 0 percentage(8 / 12);
}

.ph-col-10 {
  flex: 0 0 percentage(10 / 12);
}

.ph-col-12 {
  flex: 0 0 percentage(12 / 12);
}

.ph-avatar {
  position: relative;
  width: 100%;
  min-width: 60px;
  background-color: $ph-color;
  margin-bottom: $ph-spacer;
  border-radius: $ph-avatar-border-radius;
  overflow: hidden;

  &::before {
    content: " ";
    display: block;
    padding-top: 100%;
  }
}

.ph-picture {
  width: 100%;
  height: 120px;
  background-color: $ph-color;
  margin-bottom: $ph-spacer;
}

@keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }

  100% {
    transform: translate3d(30%, 0, 0);
  }
}