svg {
  overflow: visible;
}

.password__show {
  position: absolute;
  cursor: pointer;
  right: 1rem;
  @include large-text(2.5rem, 2.75rem, top);

  &:focus {
    path,
    circle {
      stroke: var(--primary) !important;
    }
  }
}

.password__show_alternate {
  position: absolute;
  cursor: pointer;
  right: 1.5rem;
  top: 2.5rem;

  &:focus {
    path,
    circle {
      stroke: var(--primary) !important;
    }
  }
}

.form_glyph {
  background-color: transparent;
  position: absolute;
  right: 1.1rem;
  top: 57%;

  &.white {
    background-color: white;
  }
}

.mb-1.mail {
  margin-top: -0.6rem;
}

.prof-pic {
  cursor: pointer;

  &:hover,
  &:focus {
    #tooltip svg path {
      stroke: var(--dark);
    }
  }
}

#tooltip {
  background-color: white;
  border-radius: 100%;
  padding: 0.5rem;

  //special circle rule for Safari
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      padding: 0rem 0.5rem;
    }
  }

  border: 4px solid var(--gray);
  position: relative;
  left: 3.5rem;
  bottom: 1.75rem;

  &:hover {
    svg path {
      stroke: var(--dark);
    }
  }
}

.glyph-danger {
  g path,
  link,
  circle {
    @include make-accessible(
      var(--dark-danger) !important,
      $red-dark2 !important,
      stroke
    );
  }
}

.glyph-light-gray {
  g path,
  link,
  circle,
  rect {
    stroke: var(--graydark1);
  }
}

.glyph-grooveblue {
  path {
    @include make-accessible(var(--primary), var(--dark-primary), stroke);
  }

  rect {
    @include make-accessible(var(--primary), var(--dark-primary), stroke);
  }

  circle {
    @include make-accessible(var(--primary), var(--dark-primary), stroke);
  }

  line {
    @include make-accessible(var(--primary), var(--dark-primary), stroke);
  }
}

.instruction-glyph {
  width: 50px;
}

.glyph-gray {
  path,
  line,
  circle,
  rect {
    @include make-accessible(
      var(--graydark2) !important,
      var(--secondary) !important,
      stroke
    );
  }
}

.close-icon:hover,
.close-icon:focus {
  .glyph-gray path {
    stroke: var(--danger) !important;
  }
}
.close-icon:hover {
  path {
    stroke: var(--danger) !important;
  }
}

.glyph-gray-fill {
  path {
    @include make-accessible(var(--graydark2), var(--secondary), fill);
  }
}
.btn.btn-link:hover {
  .glyph-gray-fill path {
    fill: var(--dark);
  }
}

.g-table-circle-check {
  .glyph-complete path {
    fill: var(--primary);
    stroke: var(--primary);
  }

  input:hover + .glyph-complete path,
  input:focus + .glyph-complete path {
    fill: var(--graydark1);
    stroke: var(--graydark1);
  }

  @media all and (-ms-high-contrast: none) {
    .glyph-complete:hover,
    .glyph-complete:focus {
      path {
        fill: var(--graydark1);
        stroke: var(--graydark1);
      }
    }
  }

  input:hover + .glyph-incomplete,
  input:focus + .glyph-incomplete {
    path,
    line {
      stroke: var(--primary);
    }
  }
  @media all and (-ms-high-contrast: none) {
    .glyph-incomplete:hover,
    .glyph-incomplete:focus {
      path,
      line {
        stroke: var(--primary);
      }
    }
  }
}

.mail.ml-5 {
  margin-bottom: 2px;
}

.glyph-dark-blue {
  g path,
  link,
  circle {
    stroke: var(--primary);
  }

  &.fill-dark-blue g path {
    fill: var(--primary);
  }
}

.glyph-link-blue {
  path,
  link,
  circle,
  rect {
    @include make-accessible(var(--primary), var(--dark-primary), stroke);
  }
}

.glyph-link-document {
  g path {
    fill: #0088db;
    stroke: #ffffff !important;
  }
  line,
  circle,
  rect {
    @include make-accessible(var(--primary), var(--primary), stroke);
  }
}
.glyph-warning {
  g path {
    fill: #fccb04;
    stroke: #ffffff !important;
  }
}

.glyph-warning-stroke {
  g path {
    @include make-accessible(var(--warning), var(--dark-warning), stroke);
  }
}

.glyph-white {
  g path,
  link,
  circle,
  line {
    stroke: #ffffff;
  }
}

.fill-white {
  g path {
    fill: #ffffff;
  }
}

.fill-dark {
  g path {
    fill: #1f2f36;
  }
}

button:hover {
  .fill-dark g path {
    fill: var(--graydark2);
  }
}

.btn-link {
  .glyph-fill-primary {
    path {
      @include make-accessible(var(--primary), var(--dark-primary), fill);
    }
  }

  &:hover {
    .glyph-fill-primary {
      path {
        @include make-accessible(var(--secondary), var(--dark), fill);
      }
    }
  }
}

a {
  .glyph-fill-primary {
    path {
      @include make-accessible(var(--primary), var(--dark-primary), fill);
    }
  }

  &:hover {
    .glyph-fill-primary {
      path {
        @include make-accessible(var(--secondary), var(--dark), fill);
      }
    }
  }
}

#resend-glyph {
  height: 20px;
  width: 20px;
  cursor: pointer;

  &:hover {
    path,
    line {
      stroke: var(--graydark2);
    }
  }
}

.btn-link:focus {
  .glyph-dark path {
    stroke: #1f2f36;
  }
}

.btn-glyph:hover {
  .incomplete {
    path,
    line {
      stroke: var(--secondary);
    }
  }

  .complete {
    path {
      fill: var(--extra-light-primary);
      stroke: var(--extra-light-primary);
    }
  }
}

.glyph-success path {
  stroke: var(--dark-success);
}

svg.glyph-dark {
  line,
  rect,
  path,
  polyline,
  ellipse,
  polygon,
  circle {
    stroke: #1f2f36 !important;
  }
}

button:hover,
button:focus,
a:hover {
  svg.glyph-dark {
    line,
    rect,
    path,
    polyline,
    ellipse,
    polygon,
    circle {
      stroke: var(--graydark2) !important;
    }
  }
}
button:hover,
button:focus,
a:hover {
  svg.glyph-dark-fill {
    line,
    rect,
    path,
    polyline,
    ellipse,
    polygon,
    circle {
      fill: var(--graydark2) !important;
    }
  }
}
.tab-overview-circular-progressbar {
  .CircularProgressbar {
    width: 8rem;
    height: 8rem;
    margin-top: -1rem;
  }
}

.mobile-glyph:not(svg) {
  width: 27px !important;
  height: 27px !important;
}

.mobile-glyph {
  height: 24px;
  width: 32px;

  &.mr-2 {
    height: 16px;
    width: 27px;
  }
}

.support-glyph {
  @include large-text(24px, 28px, height);
}

.incomplete .circle-check-2 {
  fill: #ffffff;
  stroke: var(--graydark1);
}

.incomplete .circle-check-3 {
  stroke: var(--graydark1);
}

.plus-1,
.bluePencil-1 {
  stroke: var(--primary);
}

.bell-on1,
.bell-on0 {
  stroke: var(--danger) !important;
}

.glyph-primary-fill {
  path {
    fill: var(--primary);
  }
}

.glyph-app-primary-fill-rect {
  rect {
    fill: var(--app-primary) !important;
  }
}
.glyph-app-primary {
  line,
  rect,
  path,
  polyline,
  ellipse,
  polygon,
  circle {
    stroke: var(--app-primary) !important;
  }
}

.dropdown-indicator:hover {
  .glyph-dark {
    line,
    rect,
    path,
    polyline,
    ellipse,
    polygon,
    circle {
      stroke: var(--secondary) !important;
    }
  }
}
