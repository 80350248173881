//Tables
// .small-med-table table td {
//   max-width: 160px;

//   &:first-of-type {
//     word-break: break-word;
//   }
// }

#flagged-exchanges td {
  vertical-align: top;
  height: 100%;
  position: relative;
}

#medications-skipped td,
#import-new-meds td {
  vertical-align: middle;
}

.outline-warning {
  outline: 2px solid var(--warning);
}

table tr th {
  white-space: nowrap;
  font-weight: normal;
}

//Gray padding for table header in Chrome
table:not(.table-condensed):not(.cerner):not(#modal-risk-profiles):not(
    .e-timeline-header-table-container
  ):not(.fc-scrollgrid):not(#modal-ehr-search) {
  thead {
    background-color: $gray-light2;

    tr th:not(.fc-day):not(.fc-timegrid-axis) {
      background-color: $gray-light2;

      position: sticky;
      border-bottom: none;
      border-top: none;

      &:first-child > span {
        &:before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          background: inherit;
          right: 100%;
          background-color: #f8f8f8;
          display: block;
          width: 35px;

          @include media-breakpoint-down(mdlg) {
            width: 15px;
          }

          @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
            height: 0rem;
          }
        }
      }

      &:last-child > span {
        &:after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          background: inherit;

          left: 100%;
          background-color: #f8f8f8;
          display: block;
          width: 35px;

          @include media-breakpoint-down(mdlg) {
            width: 15px;
          }

          @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
            height: 0rem;
          }
        }
      }
    }
  }
}

table.static,
table.table-borderless {
  thead {
    tr th:not(.fc-day):not(.fc-timegrid-axis) {
      &:first-child > span {
        &:before {
          @include media-breakpoint-down(mdlg) {
            width: 15px !important;
          }
        }
      }

      &:last-child > span {
        &:after {
          @include media-breakpoint-down(mdlg) {
            width: 15px !important;
          }
        }
      }
    }
  }
}

.overview-table .g-table-fix-head,
.admin-patient-list .g-table-fix-head {
  .overview-glyph {
    margin-left: -1.75rem;
    padding-right: 0.25rem;

    @include media-breakpoint-down(mdlg) {
      margin-left: -1rem;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        width: -1.75rem;
      }
    }
  }

  tr th:first-of-type {
    width: 0%;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      width: 5%;
    }
  }

  thead tr th:first-of-type,
  tbody tr td:first-of-type {
    padding-left: 0px;
    padding-right: 0px;
  }

  thead tr th:nth-of-type(2),
  tbody tr td:nth-of-type(2) {
    padding-left: 0px !important;
  }

  @include media-breakpoint-down(mdlg) {
    padding-left: 15px;
    padding-right: 15px;

    table.static {
      thead tr th:last-of-type,
      tbody tr td:last-of-type {
        padding-right: 15px !important;
      }

      thead {
        tr th {
          &:first-child > span {
            &:before {
              width: 15px !important;
            }
          }

          &:last-child > span {
            &:after {
              width: 15px !important;
            }
          }
        }
      }
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @include media-breakpoint-down(mdlg) {
      padding-left: 0px;
      padding-right: 0px;

      table.static {
        thead tr th:last-of-type,
        tbody tr td:last-of-type {
          padding-right: 0px !important;
        }

        thead {
          tr th {
            &:first-child > span {
              &:before {
                width: 0px !important;
              }
            }

            &:last-child > span {
              &:after {
                width: 0px !important;
              }
            }
          }
        }
      }
    }
  }
}

#modal-risk-profiles,
#modal-ehr-search {
  thead {
    background-color: $gray-light2;

    tr th:not(.fc-day):not(.fc-timegrid-axis) {
      background-color: $gray-light2;
      position: sticky;
      border-bottom: none;
      border-top: none;

      &:first-child > span {
        &:before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          background: inherit;
          right: 100%;
          background-color: #f8f8f8;
          display: block;
          width: 15px;

          @include media-breakpoint-down(mdlg) {
            width: 15px;
          }

          @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
            height: 0rem;
          }
        }
      }

      &:last-child > span {
        &:after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          background: inherit;

          left: 100%;
          background-color: #f8f8f8;
          display: block;
          width: 15px;

          @include media-breakpoint-down(mdlg) {
            width: 15px;
          }

          @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
            height: 0rem;
          }
        }
      }
    }
  }
}

@media all and (-ms-high-contrast: none) {
  thead tr th:first-child:before {
    content: none !important;
  }

  thead tr th:last-child:after {
    content: none !important;
  }
}

// .calendar-table thead tr th {
//   top: 0;
//   &:first-child:before {
//     content: none;
//   }
//   &:last-child:after {
//     content: none;
//   }
// }
.table tbody th,
.table tbody td {
  vertical-align: top;
  white-space: pre-wrap;

  // @media all and (-ms-high-contrast: none) {
  // @include media-breakpoint-down(sm) {
  word-break: break-word;
  word-wrap: break-word;
  display: table-cell;
  max-width: 300px;

  // }
  // }
  .dropdown-menu {
    white-space: nowrap;
  }
}

.table tbody {
  // font-size: 1rem;
  tr:first-of-type td {
    border-top: 0;

    .table tbody {
      tr:not(:first-of-type) td {
        border-top: 2px solid $gray;
      }
    }
  }
}

// .claims-table {
//   td:first-of-type {
//     overflow-wrap: anywhere;
//     word-break: break-all;
//   }
// }

.overview-table {
  @media all and (-ms-high-contrast: none) {
    @include media-breakpoint-down(md) {
      tr {
        td {
          max-width: 150px;
        }
      }
    }
  }
  table {
    tbody {
      tr {
        td {
          //  smaller top and bottom padding
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        }
      }
    }
  }
}

.g-table-fix-head {
  overflow-y: visible;
  padding-left: 35px;
  padding-right: 35px;

  @include media-breakpoint-down(mdlg) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  @media all and (-ms-high-contrast: none) {
    padding-left: 0;
    padding-right: 0;

    tr th:first-of-type,
    tr td:first-of-type {
      padding-left: 35px !important;
    }

    tr th:last-of-type,
    tr td:last-of-type {
      padding-right: 35px !important;
    }
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.small-med-table .g-table-fix-head {
  padding-left: 35px;
  padding-right: 35px;

  @include media-breakpoint-down(mdlg) {
    padding-left: 1rem;
    padding-right: 1rem;

    table thead tr th:first-of-type,
    table tbody tr td:first-of-type {
      padding-left: 1rem;
    }

    table thead tr th:last-of-type,
    table tbody tr td:last-of-type {
      padding-right: 1rem;
    }
  }

  @media all and (-ms-high-contrast: none) {
    padding-left: 0;
    padding-right: 0;

    /*@media all and (max-width:767.98px) and (-ms-high-contrast:none)*/
    table thead tr th:first-of-type,
    table tbody tr td:first-of-type {
      padding-left: 35px;
    }

    table thead tr th:last-of-type,
    table tbody tr td:last-of-type {
      padding-right: 35px;
    }
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      padding-left: 0;
      padding-right: 0;

      table thead tr th:first-of-type,
      table tbody tr td:first-of-type {
        padding-left: 35px;
      }

      table thead tr th:last-of-type,
      table tbody tr td:last-of-type {
        padding-right: 35px;
      }
    }
  }
}

.g-table-fix-head.grouped {
  thead tr th {
    padding: 0.75rem 0.25rem;
  }

  thead tr th:first-of-type {
    @media all and (-ms-high-contrast: none) {
      padding-left: 15px;
    }

    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        padding-left: 35px;
      }
    }
  }

  thead tr th:last-of-type {
    @media all and (-ms-high-contrast: none) {
      padding-right: 15px;
    }

    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        padding-right: 35px;
      }
    }
  }

  tbody tr td {
    padding: 1.5rem 0.25rem;
  }

  tbody tr td:first-of-type {
    padding-left: 0;

    @media all and (-ms-high-contrast: none) {
      padding-left: 35px;
    }

    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        padding-left: 35px;
      }
    }
  }

  tbody tr td:last-of-type {
    padding-right: 0;

    @media all and (-ms-high-contrast: none) {
      padding-right: 35px;
    }

    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        padding-right: 35px;
      }
    }
  }
}

.g-table {
  // padding-left: 5px;
  // padding-right: 5px;
  thead {
    background-color: $gray-light2;

    tr th {
      z-index: 2;
      padding: 0.75rem;
      background-color: $gray-light2;
      position: sticky;
      top: 0;
      //
      // @include large-text(1rem, 1.125rem, font-size);
    }
  }

  tbody {
    tr td {
      padding: 0.75rem;
      vertical-align: middle;

      // @include large-text(1rem, 1.125rem, font-size);
      @include media-breakpoint-down(mdlg) {
        padding: 0.75rem;
      }
    }
  }
}

.hover-none {
  &:hover {
    background-color: transparent !important;
  }
}

.g-table-pagination-info {
  float: right;
  @include make-accessible(var(--secondary), var(--dark), color);
  // @include large-text(1rem, 1.125rem, font-size);

  //
}

.g-table-pagination-pages {
  .btn {
    @include make-accessible(
      2px solid var(--dark),
      2px solid var(--dark-dark),
      border
    );
    @include make-accessible(var(--dark), var(--dark-dark), color);

    //
    @media (hover: hover) {
      &:hover {
        @include make-accessible(
          var(--dark),
          var(--dark-dark),
          background-color
        );
        color: white;
      }
    }
  }
}

.g-table-pagination-disabled-page {
  border: 2px solid var(--secondary) !important;

  color: var(--secondary) !important;

  cursor: default !important;

  a {
    cursor: default !important;
  }

  &:hover {
    background-color: transparent !important;
  }
}

.g-table-pagination-active-page {
  .btn {
    @include make-accessible(
      var(--dark),
      var(--dark-dark) !important,
      background-color
    );
    color: #ffffff !important;
  }
}

.import-table {
  @include media-breakpoint-up(md) {
    width: 750px;
  }

  @include media-breakpoint-up(lg) {
    width: 950px;
  }

  @include media-breakpoint-up(xl) {
    width: 1050px;
  }

  &.smart-table {
    @include media-breakpoint-up(md) {
      width: 95vw;
    }

    @include media-breakpoint-up(lg) {
      width: 1200px;
    }
  }

  @include media-breakpoint-down(sm) {
    width: 95vw;
  }

  thead tr th {
    top: 0rem !important;
    z-index: 10;
  }
}

#signedUpTable {
  th,
  td {
    padding: 0.75rem 0rem;
  }

  tr:first-of-type {
    th,
    td {
      border: none;
    }
  }
}

#inviteHistory {
  table td {
    white-space: pre-line;
  }

  // tr th:nth-of-type(2) {
  //   width: 23%;
  // }

  table thead tr th:first-child > span:before,
  table thead tr th:last-child > span:after {
    width: 35px !important;
  }

  //limit the width of the th sent via column
  // table thead tr th:nth-of-type(2) {
  //   max-width: 20px !important;
  // }

  table {
    margin-bottom: 0;
  }
}

#med-table-container {
  thead tr th:last-of-type {
    width: 6%;

    @include media-breakpoint-down(md) {
      width: 12%;
    }
  }

  thead tr th:first-of-type {
    width: 30%;
  }
  thead tr th:nth-of-type(2),
  thead tr th:nth-of-type(3) {
    width: 15%;
  }

  @include media-breakpoint-down(sm) {
    // thead tr th:first-of-type {
    //   width: 75%;
    // }
    thead tr th:last-of-type {
      width: 15%;
    }
  }

  tbody tr td:last-of-type {
    padding-right: 0;

    @media all and (-ms-high-contrast: none) {
      padding-right: 35px !important;

      @include media-breakpoint-down(sm) {
        padding-right: 1rem;
      }
    }

    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        padding-right: 35px !important;

        @include media-breakpoint-down(sm) {
          padding-right: 1rem;
        }
      }
    }
  }
}

#edit-meds-table,
#add-meds-table {
  thead tr th {
    top: 0;
    z-index: 100;
  }
}

// #edit-meds-table
// {
//   @include media-breakpoint-up(md) {
//     thead tr th:first-of-type {
//       width: 23%;
//     }
//     thead tr th:last-of-type {
//       width: 5%;
//     }

//   }
// }
#import-table {
  @include media-breakpoint-up(md) {
    thead tr th:first-of-type {
      width: 35%;
    }

    thead tr th:last-of-type {
      width: 5%;
    }

    thead tr th:nth-of-type(2) {
      white-space: nowrap;
      width: 35%;
    }
  }
}

#patient-list {
  tr th:first-of-type {
    width: 3%;
  }

  tr th:not(:first-of-type) {
    width: 17%;

    @include media-breakpoint-down(sm) {
      width: 35%;
    }
  }

  @include media-breakpoint-up(md) {
    tr th:last-of-type {
      width: 3%;
    }
  }

  @include media-breakpoint-down(mdlg) {
    tr th:nth-of-type(3) {
      width: 13% !important;
    }

    tr th:nth-of-type(4) {
      width: 16%;
    }
  }
}

// #manage-invites tr th:not(:first-of-type) {
//   @include media-breakpoint-down(mdlg) {
//     width: 6%;
//   }
// }
// #manage-invites tr th:last-of-type {
//   @include media-breakpoint-down(sm) {
//     width: 25%;
//   }
// }

#manage-invites {
  tr th:first-of-type {
    width: 1%;
  }

  @include media-breakpoint-up(xl) {
    tr th:not(:first-of-type) {
      width: 13%;
    }
  }

  @include media-breakpoint-down(sm) {
    tr th:nth-of-type(3) {
      width: 60%;
    }

    tr th:nth-of-type(2) {
      width: 35%;
    }
  }
}

#user-invites tr {
  th:first-of-type {
    @include media-breakpoint-up(md) {
      width: 1%;
    }
  }

  th:nth-of-type(3),
  th:nth-of-type(4) {
    @include media-breakpoint-only(lg) {
      width: 13%;
    }
  }

  th:nth-of-type(7) {
    @include media-breakpoint-up(mdlg) {
      width: 14%;
    }
  }
}

#connection-requests {
  tr th:not(:first-of-type) {
    @include media-breakpoint-up(md) {
      width: 21.25%;
    }

    @include media-breakpoint-only(md) {
      width: 24%;
    }

    @include media-breakpoint-only(mdlg) {
      width: 24%;
    }
  }

  tr th:last-of-type {
    width: 16%;

    @include media-breakpoint-up(md) {
      width: 10%;
    }
  }

  tr th:first-of-type {
    width: 17%;
  }
}

#archived-requests {
  tr th {
    // @include media-breakpoint-only(md) {
    //   width: 21%;
    // }
    tr th:last-of-type {
      width: 70%;

      @include media-breakpoint-up(md) {
        width: 10%;
      }
    }
  }
}

.six-col tr th:not(:last-of-type) {
  @include media-breakpoint-down(mdlg) {
    width: 25%;
  }

  @include media-breakpoint-down(sm) {
    width: 35%;
  }
}

.admin-patient-list {
  tr th:not(:last-of-type):not(:first-of-type) {
    width: 32%;
  }

  thead tr th:nth-of-type(2) {
    @include media-breakpoint-down(sm) {
      width: 50%;
    }
  }
}

.group-patient-list {
  tr th:nth-of-type(2) {
    width: 40%;
  }

  thead tr th:first-of-type {
    @include media-breakpoint-down(sm) {
      width: 50%;
    }
  }
}

.g-table.static td {
  vertical-align: middle;
}

#mobile-table {
  thead tr th:first-of-type {
    width: 70%;
  }
}

.small-med-table {
  tbody tr td {
    padding: 0.75rem 0.75rem;
  }

  // thead tr th:not(:last-of-type) {
  //   width: 20% !important;
  // }

  // thead tr th:nth-of-type(2) {
  //   width: 12%;
  // }
  // thead tr th:nth-of-type(3),
  // thead tr th:nth-of-type(4) {
  //   width: 15%;
  // }

  thead tr th:last-of-type {
    white-space: nowrap;
    width: 1%;
  }
}

table:not(.plan-table):not(.plan-table-multi):not(.cerner):not(.e-lib) {
  thead:not(.e-week-header) tr th:first-of-type,
  tr td:first-of-type:not(.e-cell):not(.fc-day):not[role="presentation"] {
    padding-left: 0;

    @include media-breakpoint-down(sm) {
      padding-right: 0;
    }

    @media all and (-ms-high-contrast: none) {
      padding-left: 35px !important;
    }
  }

  thead:not(.e-week-header) th:last-of-type,
  tr td:last-of-type:not(.e-cell):not(.fc-day):not[role="presentation"] {
    padding-right: 0;

    @media all and (-ms-high-contrast: none) {
      padding-right: 35px !important;
    }
  }
}

table {
  thead.e-week-header tr th:first-of-type {
    @media all and (-ms-high-contrast: none) {
      padding-left: 0 !important;
    }
  }

  // tr td:first-of-type {
  //   @media all and (-ms-high-contrast: none) {
  //     padding-left: 0 !important;
  //   }
  // }
}

table {
  overflow-x: hidden;
}

// @media all and (-ms-high-contrast: none) {
//   table thead:not(.e-week-header) tr th:first-of-type,
//   table tbody tr td:not(.fc-day):first-of-type {
//     padding-left: 35px;
//     @include media-breakpoint-down(sm) {
//       padding-left: 1rem;
//     }
//   }
//   table thead tr th:last-of-type,
//   table tbody tr td:not(.fc-day):last-of-type {
//     padding-right: 35px;
//     @include media-breakpoint-down(sm) {
//       padding-right: 1rem;
//     }
//   }
//   // table.fc-scrollgrid tbody tr td,
//   // table.fc-scrollgrid thead tr th {
//   //   padding-left: 0 !important;
//   //   padding-right: 0 !important;
//   // }
// }
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    table thead tr th:first-of-type,
    table tbody tr td:not(.fc-day):first-of-type {
      padding-left: 35px;

      @include media-breakpoint-down(sm) {
        padding-left: 1rem;
      }
    }

    table thead tr th:last-of-type,
    table tbody tr td:not(.fc-day):last-of-type {
      padding-right: 35px;

      @include media-breakpoint-down(sm) {
        padding-right: 1rem;
      }
    }
  }
}

.card-body.ie-no-padding {
  @media all and (-ms-high-contrast: none) {
    padding-left: 0 !important;
    padding-right: 0 !important;

    tr th:first-of-type,
    tr td:first-of-type {
      padding-left: 35px !important;
    }

    tr th:last-of-type,
    tr td:last-of-type {
      padding-right: 35px !important;
    }
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  // @include media-breakpoint-only(md) {
  //   padding: 1rem 1rem 1.5rem;
  //   }
}

.ie-padding {
  @media all and (-ms-high-contrast: none) {
    padding-left: 35px;
    padding-right: 35px;
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      padding-left: 35px;
      padding-right: 35px;
    }
  }
}

td.border-0.pt-0 {
  vertical-align: top;
}

span[title="Toggle Row Expanded"] {
  width: 20px;
  height: 20px;
}

table.cerner {
  tbody {
    background-color: white;
  }

  thead tr th:first-of-type {
    width: 4%;
  }

  thead tr th:not(:last-of-type):not(:first-of-type) {
    width: 13%;
  }

  border: 1px solid #c8cacc;

  tr th,
  tr td {
    padding: 0.25rem 0.75rem;
    vertical-align: middle;
    border: 0;
    white-space: pre-wrap;
  }

  thead tr th {
    border: 1px solid #c8cacc;
    border-bottom: 2px solid #64696c;
    color: #7c6982;
    font-weight: 500;
    background-color: #f4f4f4;
  }

  tbody tr {
    color: #1c1f21;
  }

  // tbody tr:nth-of-type(odd) {
  //   background-color: white;
  // }
  // tbody tr:nth-of-type(even) {
  //   background-color: #f4f4f4;
  // }
  .sub-row td {
    border-top: 2px dashed var(--primary);
    border-bottom: 2px solid var(--primary);
  }

  .sub-row,
  td.bulk-td {
    position: relative;
  }

  .sub-row td:first-of-type:before {
    content: " ";
    position: absolute;
    height: calc(100% + 2px);
    left: -1px;
    top: -1px;
    border-left: 16px solid var(--primary);
  }

  td.bulk-td:before {
    content: " ";
    position: absolute;
    height: calc(100% + 2px);
    left: -1px;
    top: -1px;
    border-left: 16px solid var(--primary);
  }

  .sub-row td:last-of-type {
    border-right: 2px solidvar(--primary);
  }

  tr.expanded {
    border-top: 2.1px solid var(--primary);
    border-right: 2px solid var(--primary);
    border-left: 2px solid var(--primary);
  }

  &.blue-outline {
    border: 2px solid var(--primary);
  }

  @media all and (-ms-high-contrast: none) {
    border-collapse: separate;
    border-spacing: 0;
  }
}

// .alerts-table :not(.ddi-sidebar_){
//   .sub-row,
//   td.bulk-td,
//   tr.expanded {
//     position: relative;
//   }

//   // .sub-row td {
//   //   border-bottom: 2px solid var(--primary);
//   // }
//   .sub-row td:first-of-type:before {
//     content: " ";
//     position: absolute;
//     height: calc(100% + 2px);
//     left: -1px;
//     top: -1px;
//     border-left: 16px solid var(--primary);
//   }

//   .sub-row td:first-of-type:after {
//     content: " ";
//     position: absolute;
//     height: calc(100% + 2px);
//     left: -36px;
//     top: -1px;
//     border-left: 2px solid var(--primary);
//   }

//   .sub-row td:last-of-type:after {
//     content: " ";
//     position: absolute;
//     height: calc(100% + 2px);
//     right: -36px;
//     top: -1px;
//     border-left: 2px solid var(--primary);
//   }

//   tr.sub-row::after {
//     content: " ";
//     position: absolute;
//     right: -36px;
//     left: -36px;
//     bottom: -1px;
//     border-bottom: 2px solid var(--primary);
//   }

//   td.bulk-td:before {
//     content: " ";
//     position: absolute;
//     height: calc(100% + 2px);
//     left: -1px;
//     top: -1px;
//     border-left: 16px solid var(--primary);
//   }

//   tr.expanded td:first-of-type::before {
//     content: " ";
//     position: absolute;
//     height: calc(100% + 2px);
//     left: -36px;
//     top: 0px;
//     border-left: 2px solid var(--primary);
//   }

//   tr.expanded td:last-of-type::after {
//     content: " ";
//     position: absolute;
//     height: calc(100% + 2px);
//     right: -36px;
//     top: 0px;
//     border-right: 2px solid var(--primary);
//   }

//   tr.expanded:after {
//     content: " ";
//     position: absolute;
//     right: -36px;
//     left: -36px;
//     top: 0px;
//     border-top: 2px solid var(--primary);
//   }
// }

#task-table {
  tr th:first-of-type {
    width: 8%;
  }

  tr th:nth-of-type(2) {
    @include media-breakpoint-up(lg) {
      width: 20%;
    }

    @include media-breakpoint-down(mdlg) {
      width: 35%;
    }
  }

  tr th:last-of-type {
    @include media-breakpoint-up(lg) {
      width: 5%;
    }

    // @include media-breakpoint-only(md) {
    //   width: 35%;
    // }
  }

  &.smart-widths {
    tr th:nth-of-type(2) {
      @include media-breakpoint-up(md) {
        width: 28%;
      }

      @include media-breakpoint-down(sm) {
        width: 35%;
      }
    }

    tr th:nth-of-type(4),
    tr th:nth-of-type(5) {
      @include media-breakpoint-up(md) {
        width: 18%;
      }
    }
  }

  .sub-row,
  td.bulk-td,
  tr.expanded {
    position: relative;
  }

  .sub-row td {
    vertical-align: top;
  }

  // .sub-row td {
  //   border-bottom: 2px solid var(--primary);
  // }
  .sub-row td:nth-of-type(2):before {
    content: " ";
    position: absolute;
    height: calc(100% + 2px);
    left: 2rem;
    top: -1px;
    border-left: 16px solid var(--primary);
  }

  .sub-row td:nth-of-type(2):after {
    content: " ";
    position: absolute;
    height: calc(100% + 2px);
    left: -16px;
    top: -1px;
    border-left: 2px solid var(--primary);
  }

  .sub-row td:last-of-type:after {
    content: " ";
    position: absolute;
    height: calc(100% + 2px);
    right: -16.5px;
    top: -1px;
    border-left: 2px solid var(--primary);
  }

  tr.sub-row::after {
    content: " ";
    position: absolute;
    right: -16px;
    left: -16px;
    bottom: -1px;
    border-bottom: 2px solid var(--primary);
  }

  tr.expanded td:nth-of-type(2)::before {
    content: " ";
    position: absolute;
    height: calc(100% + 2px);
    left: -16px;
    top: 0px;
    border-left: 2px solid var(--primary);
  }

  tr.expanded td:last-of-type::after {
    content: " ";
    position: absolute;
    height: calc(100% + 2px);
    right: -16px;
    top: 0px;
    border-right: 2px solid var(--primary);
  }

  tr.expanded:after {
    content: " ";
    position: absolute;
    right: -16px;
    left: -16px;
    top: 0px;
    border-top: 2px solid var(--primary);
  }
}

table.g-table.static.table thead.ddi-table-head th:first-of-type {
  width: 70%;
}

table.sidebar-table tr td {
  font-weight: 500;
  padding: 0.25rem 0.75rem;
  position: relative;
}

table.sidebar-table tr:not(:last-of-type) td:not(:first-of-type):after {
  content: " ";
  position: absolute;
  height: 20px;
  left: 0;
  bottom: -10px;
  border-left: 2px solid var(--gray);
}

#reports-table {
  tr th:last-of-type {
    // width: 50%;

    @include media-breakpoint-up(lg) {
      width: 10%;
      text-align: end;
      padding-right: 0.5rem;
    }

    @include media-breakpoint-up(md) {
      width: 5%;
    }
  }
}

#patient-list #patient-list-management {
  tr th:last-of-type {
    @include media-breakpoint-up(lg) {
      width: 8%;
    }
  }

  // tr td:last-of-type {
  //   padding-top: 0 !important;
  //   padding-bottom: 0 !important;
  // }

  tr th:first-of-type {
    width: 1%;
  }

  tr td:first-of-type {
    padding: 0;
  }
}

.admin--user-table {
  // tr th:nth-of-type(2),
  tr th:first-of-type,
  tr th:nth-of-type(7) {
    @include media-breakpoint-only(xl) {
      width: 12%;
    }
  }

  // tr th:nth-of-type(5) {
  //   @include media-breakpoint-only(lg) {
  //     padding: 0.58rem;
  //   }
  // }
}

.cycle-table tr {
  td,
  th {
    vertical-align: middle;
  }

  th:nth-of-type(5) {
    width: 31%;
  }

  th:first-of-type {
    width: 9%;
  }

  th:last-of-type {
    width: 1%;
  }
}

table.plan-table,
table.plan-table-multi {
  tr th {
    background-color: white !important;
    font-weight: 500;
    border-bottom: 2px solid $border-color !important;
  }

  tr td {
    background-color: white;
    vertical-align: middle;
  }

  box-shadow: $box-shadow;
  border-radius: $border-radius;
}

table.plan-table {
  tr th:first-of-type {
    width: 12%;
  }

  tr th:last-of-type {
    width: 34%;
  }
}

table.plan-table-multi {
  tr th:first-of-type {
    width: 40%;
  }

  tr th:last-of-type {
    width: 34%;
  }
}

#outreach-table {
  tr th:last-of-type {
    @include media-breakpoint-up(md) {
      width: 5%;
    }
  }

  tr th:nth-of-type(6) {
    width: 22%;
  }

  tr th:first-of-type {
    width: 16%;
  }
}

.ddi-sidebar .g-table-fix-head {
  @include media-breakpoint-down(mdlg) {
    padding-left: 35px;
    padding-right: 35px;

    table thead tr th:first-of-type,
    table tbody tr td:first-of-type {
      padding-left: 35px !important;
    }

    table thead tr th:last-of-type,
    table tbody tr td:last-of-type {
      padding-right: 35px !important;
    }

    table thead tr th:first-child > span:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      background: inherit;
      right: 100%;
      background-color: #f8f8f8;
      display: block;
      width: 35px !important;
    }

    table thead tr th:last-child > span:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      background: inherit;
      right: 100%;
      background-color: #f8f8f8;
      display: block;
      width: 35px !important;
    }
  }
}

#notification-settings-table {
  thead tr th {
    background-color: white !important;
  }

  tr td:not(:first-of-type) {
    text-align: center;
  }
}

.suggested-meds-table .g-table.static td {
  vertical-align: top;
}

table.e-timeline-header-table-container {
  overflow-x: unset;
}

.e-gantt .e-gantt-chart .e-gantt-parent-taskbar-inner-div {
  background-color: white;
  outline: 0px solid white;
}

.e-gantt .e-gantt-chart .e-gantt-parent-progressbar-inner-div {
  background-color: white;
  border: 0px solid white;
}

.e-gantt .e-gantt-chart .e-gantt-child-taskbar-inner-div {
  background: var(--primary);
  outline: 1px solid var(--primary);
}

.e-gantt .e-gantt-chart .e-gantt-child-progressbar-inner-div {
  background-color: var(--primary);
}

td.bullet {
  width: 5px;
}

td.list {
  width: 40px;
}
