#adh-chart_Annotation_0 {
  width: 100%;
  padding: 0px 16px 0px 70px;
  left: 0 !important;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    width: 102%;
    left: -22px !important;
  }
}

#adh-chartAxisLabels0 text:last-of-type,
#adh-chartAxisGroup0Inside path:last-of-type {
  display: none;
}

#adh-chart {
  font-family: "Effra", sans-serif !important;
  font-size: 14px;
  color: var(--dark);
}

#adh-chart_stripline_Over_text_primaryXAxis_0 {
  // move unit to left in ie only
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    transform: translate(-30px, 10px);
  }
}

#adh-chart_stripline_Over_text_primaryXAxis_1 {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    transform: translateY(10px);
  }
}
