//for alerts before login
.alert-danger {
  @include make-accessible(var(--dark), var(--dark-dark), color);
  background-color: $red-light3;
  border: 2px solid var(--danger);
}

.alert {
  display: flex;
  align-items: center;
  font-weight: 500;
}

//toast rules
.toast {
  &.fade {
    transition: opacity 0.15s linear;
  }
  max-width: fit-content;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    width: auto;
    white-space: nowrap;
    display: inline-block !important;
    max-width: none;
  }
  //This rule for IE11 keep the toasts from all appearing on one line at a large screen size
  @include media-breakpoint-up(xl) {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      margin-right: 8rem;
    }
  }
  border: 0;
  box-shadow: 0 1px 3px 0 rgba(31, 47, 54, 0.3);
  &.moving {
    opacity: 1;
    animation-name: slideup;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }
}

.toast .bg-success {
  @include make-accessible(
    var(--success),
    var(--dark-success) !important,
    background-color
  );
  width: 4.625rem;
  @include media-breakpoint-down(lg) {
    width: 8rem;
  }
}

.toast .bg-warning {
  @include make-accessible(
    var(--warning),
    var(--dark-warning) !important,
    background-color
  );
  width: 4.625rem;
  @include media-breakpoint-down(lg) {
    width: 8rem;
  }
}

.toast .bg-danger {
  @include make-accessible(
    var(--danger),
    var(--dark-danger) !important,
    background-color
  );
  width: 4.625rem;
  @include media-breakpoint-down(lg) {
    width: 8rem;
  }
}

.toast .bg-primary {
  @include make-accessible(
    var(--primary),
    var(--primary) !important,
    background-color
  );
  width: 4.625rem;
  @include media-breakpoint-down(lg) {
    width: 8rem;
  }
}

.toast.out-of-viewport {
  .bg-success,
  .bg-primary,
  .bg-danger,
  .bg-warning,
  .close,
  .pr-6 {
    transition: opacity 0.1s linear;
    opacity: 0.4;
  }
}

.alert-container {
  pointer-events: none;
  z-index: 2000;
  position: fixed;
  @include media-breakpoint-up(md) {
    left: 1.6875rem;
  }
  @include media-breakpoint-only(md) {
    top: 78%;
  }
  @include media-breakpoint-only(mdlg) {
    top: 78%;
  }
  @include media-breakpoint-down(sm) {
    top: auto;
    bottom: 5%;
  }
  top: 82.5%;
  @include media-breakpoint-up(lg) {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      max-width: 50%;
    }
  }
}

.toast-body {
  font-size: 1.125rem;
  padding: 1.25rem 1.5rem 1.25rem 2.25rem;
  background-color: white;
}

//This is for the info toast because right now it is the longest one
.toast .bg-primary .toast-body {
  white-space: pre-wrap;
}

@keyframes slideup {
  from {
    transform: translateY(38px);
  }
  to {
    transform: none;
  }
}

#banner {
  @include media-breakpoint-down(xxl) {
    height: 52px;
  }
  @include media-breakpoint-down(sm) {
    line-height: 1;
  }
}
