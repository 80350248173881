.font-size-normal {
  @include large-text(1rem, 1.125rem, font-size);
}

.extra-small {
  @include large-text(0.75rem, 0.875rem, font-size);
}

.small {
  @include large-text(0.875rem, 1rem, font-size);
}

.large {
  @include large-text(1.125rem, 1.25rem font-size);
}
body,
caption,
th,
td {
  font-family: "Effra", sans-serif !important;
}
.large-font-theme {
  font-size: 1.125rem;
  h1 {
    font-size: 3.5625rem;
  }
  h2 {
    font-size: 2.125rem;
  }
  h3 {
    font-size: 1.6875rem;
  }
  h4 {
    font-size: 1.4375rem;
  }
  h5 {
    font-size: 1.25rem;
  }
  h6 {
    font-size: 1.125rem;
  }
}

.info-key {
  font-weight: 500;
}

.text-danger {
  @include make-accessible(var(--danger), var(--dark-danger) !important, color);
  &:disabled,
  &.disabled {
    color: var(--secondary) !important;
  }
}

.text-graydark2 {
  @include make-accessible(
    var(--graydark2),
    var(--secondary) !important,
    color
  );
}
.text-primary {
  @include make-accessible(
    var(--primary) !important,
    var(--dark-primary) !important,
    color
  );
}
.text-app-primary {
  color: var(--app-primary) !important;
}

.bg-danger {
  @include make-accessible(
    var(--danger),
    var(--dark-danger) !important,
    background-color
  );
}

.bg-warning {
  @include make-accessible(
    var(--warning),
    var(--dark-warning) !important,
    background-color
  );
}

.bg-success {
  @include make-accessible(
    var(--success),
    var(--dark-success) !important,
    background-color
  );
}

.text-success {
  @include make-accessible(
    var(--success),
    var(--dark-success) !important,
    color
  );
}

@include media-breakpoint-down(sm) {
  h2.size-adj {
    font-size: 21px;
  }
  h3.size-adj {
    font-size: 16px;
  }
}

* {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.e-control {
  font-family: "Effra", sans-serif !important;
  @include large-text(14px, 16px, font-size);
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-bold {
  font-weight: bold;
}
