.card {
  box-shadow: $box-shadow;
  border: none;

  &.border-danger {
    border: 2px solid $danger;
  }

  &.border-primary {
    border: 2px solid var(--primary);
  }

  margin-bottom: 1.5rem;

  &.bg-white {
    background-color: #ffffff;
  }
}

.card-header {
  padding: 0.5rem 1.5rem;
  border-bottom: 0;
  font-size: 21px;
}

.card-body {
  padding: 35px 35px 1.5rem;

  @include media-breakpoint-down(sm) {
    padding: 1rem 1rem 1.5rem;
  }
}

.card-body-resources {
  padding: 23px 15px 1.5rem;

  @include media-breakpoint-down(sm) {
    padding: 1rem 1rem 1.5rem;
  }
}

#manualSignIn.card-body {
  @include media-breakpoint-down(sm) {
    padding: 35px 35px 1.5rem;
  }
}

#inviteHistory.card-body {
  @include media-breakpoint-down(sm) {
    padding: 35px 35px 1.5rem;
  }
}

#login-container .card-body {
  @include media-breakpoint-only(sm) {
    padding: 35px 35px 1.5rem;
  }
}

.card-body:focus,
.card:focus {
  outline: 0 !important;
}

.card.align-self-center,
form.align-self-center {

  // width: 550px;
  @include media-breakpoint-down(sm) {
    max-width: 100vw;
  }
}

.register-card {
  width: 550px;

  @include media-breakpoint-down(sm) {
    width: auto;
    max-width: 550px;
  }
}

.btn-outline {
  border: 3px solid var(--primary);
  border-radius: 5px;
  color: var(--primary);
  cursor: pointer;
}

.group-card {
  background-color: #ffffff;
  height: 14.28rem;

  .group-title {
    @extend h3;
    white-space: nowrap;
  }

  .group-btn {
    @include large-text(1rem, 1.125rem, font-size);
    width: 10rem;
  }
}

.patient-alert-card {
  background-color: #ffffff;

  .card-title {
    @extend h4;
    margin-bottom: 1rem;
  }

  .card-info {
    line-height: 1.2;
    @include large-text(1.125rem, 1.25rem, font-size);
  }

  .card-number {
    font-weight: 500;
    font-size: 38px;
  }

  .card-val {
    @extend h2;

    @include media-breakpoint-up(sm) {
      float: left;
    }

    margin-right: 0.5rem !important;
    font-weight: 500;
  }
}

.card .form-control {
  background-color: var(--light);
}

#overlay {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  z-index: 10;
  cursor: pointer;
}

.no-margin .card {
  margin-bottom: 0;
}

.smart-invite-form {
  .card.invite {
    width: 30rem;
  }
}

.accept-terms {
  .card {
    max-width: 40rem;
  }
}

.blocked-user {
  .card {
    max-width: 30rem;
  }
}

.bulk-invite-form {
  .card.invite {
    max-width: 40rem;
  }
}

#print-leaflets,
#print-interactions {
  box-shadow: none;
}