.btn {
  transition: all 0.2s ease;
  @include large-text(1rem, 1.125rem, font-size);
  font-weight: 500;

  &:disabled {
    pointer-events: none;
  }
}

a:not(.btn):not(.nav-link):not(.dropdown-item):not(.e-link):not(
    .fc-daygrid-day-number
  ) {
  //  @include make-accessible(var(--primary), var(--dark-primary), color);
  font-weight: 500;

  &:hover {
    @include make-accessible(var(--graydark2), var(--secondary), color);
    text-decoration: none;
  }

  &.disabled {
    color: var(--graydark2);
    pointer-events: none;
  }
}

.btn-primary {
  @include make-accessible(
    var(--primary),
    var(--dark-primary),
    background-color
  );
  @include make-accessible(var(--primary), var(--dark-primary), border-color);

  svg path {
    stroke: #ffffff;
  }

  &:disabled {
    @include make-accessible(
      var(--graydark1),
      var(--graydark2),
      background-color
    );
    @include make-accessible(var(--graydark1), var(--graydark2), border-color);
    opacity: 1;
    @include make-accessible(var(--info), $gray-light2, color);
  }

  &:hover {
    @include make-accessible(
      var(--dark-primary) !important,
      var(--primary) !important,
      background-color
    );
    @include make-accessible(
      var(--dark-primary) !important,
      var(--primary) !important,
      border-color
    );
  }

  &:active {
    @include make-accessible(
      var(--dark-primary) !important,
      var(--primary) !important,
      background-color
    );
    @include make-accessible(
      var(--dark-primary) !important,
      var(--primary) !important,
      border-color
    );
  }

  &:focus {
    @include make-accessible(
      var(--dark-primary) !important,
      var(--primary) !important,
      background-color
    );
    @include make-accessible(
      var(--dark-primary) !important,
      var(--primary) !important,
      border-color
    );
  }

  color: white;
}

.btn-outline-dark {
  background: white;
  border: 2px solid var(--dark);
}

.close {
  opacity: 1;

  svg {
    width: 12px;
    height: 12px;
  }

  &.minus-btn {
    svg {
      width: 18px;
      height: 18px;
    }
  }

  &:hover {
    cursor: pointer;
    opacity: 1;
    @include make-accessible(var(--graydark2), var(--secondary), color);

    svg path {
      @include make-accessible(var(--graydark2), var(--secondary), stroke);
    }
  }
}

.image-button {
  border: none;
  padding: 0;
  margin: 0;
}

.btn-outline-secondary {
  &:focus {
    border-color: var(--dark);
    color: var(--dark);
  }
}

.btn.btn-filter {
  @include make-accessible(var(--dark), var(--dark-dark), color);
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -0.25rem;
    height: 0;
    border: 1px solid var(--graydark1);
    background: var(--graydark1);
    border-radius: 5px;
  }
}

a.text-secondary.btn:hover {
  @include make-accessible(var(--dark), var(--dark-dark), color);
}

.btn.btn-link {
  line-height: 1.4;
  padding-left: 0;
  padding-right: 0;
  font-weight: normal;
  @include make-accessible(var(--primary), var(--dark-primary), color);
  background-color: transparent;
  border: none;
  cursor: pointer;

  svg {
    path {
      @include make-accessible(var(--primary), var(--dark-primary), stroke);
    }

    line {
      @include make-accessible(var(--primary), var(--dark-primary), stroke);
    }

    rect {
      @include make-accessible(var(--primary), var(--dark-primary), stroke);
    }
  }

  &:not(:disabled):not(.disabled).active {
    border-color: transparent;
  }

  &:hover {
    text-decoration: none;
  }

  &:hover,
  &:focus,
  &:not(.disabled):active {
    // text-decoration: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
    border: none;
    @include make-accessible(var(--graydark2), var(--secondary), color);

    svg {
      path,
      line,
      rect {
        @include make-accessible(var(--graydark2), var(--secondary), stroke);
      }
    }
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
    border-radius: $border-radius;
  }

  &:focus:not(:focus-visible) {
    text-decoration: underline;
  }

  &.image-only:focus-visible {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
    border-radius: $border-radius;
  }

  &.text-secondary {
    svg {
      path {
        @include make-accessible(var(--secondary), var(--graydark2), stroke);
      }

      line {
        @include make-accessible(var(--secondary), var(--graydark2), stroke);
      }

      rect {
        @include make-accessible(var(--secondary), var(--graydark2), stroke);
      }
    }

    &:hover {
      @include make-accessible(
        var(--dark) !important,
        var(--dark-dark) !important,
        color
      );

      svg {
        path {
          @include make-accessible(var(--dark), var(--dark-dark), stroke);
        }

        line {
          @include make-accessible(var(--dark), var(--dark-dark), stroke);
        }

        rect {
          @include make-accessible(var(--dark), var(--dark-dark), stroke);
        }
      }
    }

    &:focus {
      @include make-accessible(
        var(--dark) !important,
        var(--dark-dark) !important,
        color
      );

      svg {
        path {
          @include make-accessible(var(--dark), var(--dark-dark), stroke);
        }

        line {
          @include make-accessible(var(--dark), var(--dark-dark), stroke);
        }

        rect {
          @include make-accessible(var(--dark), var(--dark-dark), stroke);
        }
      }
    }

    &:focus {
      color: $navy !important;
      outline: none !important;
      box-shadow: none !important;
    }

    &:disabled {
      @include make-accessible(var(--gray), var(--graydark2), color);
      background-color: transparent;
      border: none;
    }
  }

  &.text-dark {
    svg {
      path,
      line,
      rect {
        @include make-accessible(var(--dark), var(--dark-dark), stroke);
      }
    }
  }

  &.text-danger:focus {
    color: $red-dark2 !important;
  }

  button.w-40 {
    width: 40%;
  }
}

.btn-link svg.glyph-primary-fill {
  path {
    @include make-accessible(var(--primary), var(--dark-primary), fill);
    stroke: none;
  }
}

.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  svg.glyph-primary-fill {
    path {
      @include make-accessible(var(--secondary), var(--graydark2), fill);
      stroke: none;
    }
  }
}

//For pagination on tables
.btn-xs {
  padding: 0.1rem 0.4rem;
  font-size: 0.8rem;
  line-height: 1.2;
  border-radius: 0.2rem;
}

.btn-text-danger {
  @include make-accessible(var(--danger), var(--dark-danger) !important, color);

  &:hover,
  &:focus {
    @include make-accessible(var(--dark-danger), $red-dark2 !important, color);
  }
}

.btn-outline-danger {
  @include make-accessible(var(--danger), var(--dark-danger), color);
  @include make-accessible(var(--danger), var(--dark-danger), border-color);

  &:hover {
    @include make-accessible(
      var(--danger),
      var(--dark-danger),
      background-color
    );
    color: white;
  }

  &:focus {
    @include make-accessible(var(--dark-danger), var(--danger), border-color);
    @include make-accessible(var(--dark-danger), var(--danger), color);
  }

  &:hover:focus {
    @include make-accessible(
      var(--danger),
      var(--dark-danger),
      background-color
    );
    @include make-accessible(var(--danger), var(--dark-danger), border-color);
    color: white;
  }
}

.btn-danger {
  @include make-accessible(var(--danger), var(--dark-danger), background-color);
  @include make-accessible(var(--danger), var(--dark-danger), border-color);

  &:hover {
    @include make-accessible(
      var(--dark-danger),
      var(--danger),
      background-color
    );
    @include make-accessible(var(--dark-danger), var(--danger), border-color);
  }

  &:focus {
    @include make-accessible(
      var(--dark-danger),
      var(--danger),
      background-color
    );
    @include make-accessible(var(--dark-danger), var(--danger), border-color);
  }

  &:disabled {
    @include make-accessible(
      var(--graydark1),
      var(--graydark1),
      background-color
    );
    @include make-accessible(var(--graydark1), var(--graydark1), border-color);
    color: var(--info);
    opacity: 1;
  }
}

.btn-outline-primary {
  @include make-accessible(var(--primary), var(--dark-primary), color);
  @include make-accessible(var(--primary), var(--dark-primary), border-color);

  &:hover {
    @include make-accessible(var(--primary), var(--dark-primary), border-color);

    @include make-accessible(
      var(--primary),
      var(--dark-primary),
      background-color
    );
    color: white;
  }

  &:active {
    @include make-accessible(
      var(--primary) !important,
      var(--primary) !important,
      background-color
    );
    @include make-accessible(
      var(--primary) !important,
      var(--primary) !important,
      border-color
    );
  }

  &:focus {
    @include make-accessible(var(--dark-primary), var(--primary), border-color);
    @include make-accessible(var(--dark-primary), var(--primary), color);
  }

  &:hover:focus {
    @include make-accessible(
      var(--primary),
      var(--dark-primary),
      background-color
    );
    @include make-accessible(var(--primary), var(--dark-primary), border-color);
    color: white;
  }

  &:disabled {
    @include make-accessible(var(--info), var(--graydark1), color);
    @include make-accessible(var(--info), var(--graydark1), border-color);
    background-color: var(--gray);
  }
}

a.nav-link {
  color: $dark;
  font-size: $font-size-base;

  // font-weight: 500;
  &.table-header {
    font-weight: 400;
    padding: 0;

    @include media-breakpoint-up(md) {
      margin: 0rem 1.5rem;
    }

    @include media-breakpoint-down(sm) {
      margin: 0.5rem 1rem;
    }

    &.active,
    &:active {
      text-decoration: none;
      position: relative;
      @include make-accessible(var(--primary), var(--dark-primary), color);
      background-color: transparent !important;

      &:after {
        content: "";
        position: absolute;
        z-index: 1;
        left: 0.2rem;
        right: 0.2rem;
        bottom: -0.2rem;
        height: 0;
        @include make-accessible(
          var(--primary),
          var(--dark-primary),
          background-color
        );
        @include make-accessible(
          1px solid var(--primary),
          1px solid var(--dark-primary),
          border
        );
        border-radius: 5px;
      }
    }
  }
}

.pointer,
.btn,
.btn-filter,
#tooltip svg,
#tooltip,
#tooltip label {
  cursor: pointer;
}

// .adh-btn:hover,
// .adh-btn:not(:disabled):not(.btn.btn-white.slick-disabled):not(.disabled):active,
// .adh-btn.btn-outline-primary {
//   @include make-accessible(white, var(--primary), background-color);
//   @include make-accessible(var(--primary), var(--dark-primary), border-color);
//   @include make-accessible(white,var(--primary),  color);
// }
// .adh-btn:active:hover {
//   @include make-accessible(var(--dark-primary), var(--primary), background-color);

// }
// .adh-btn:focus {
//   @include make-accessible(var(--primary), var(--dark-primary), border-color);
//   @include make-accessible(var(--primary), var(--dark-primary), color);
//   &active {
//     color: white;
//   }
// }
.patient-view-nav .active:hover {
  color: inherit !important;
}

.active:hover {
  cursor: default;
}

//just for login button to make it as big as the form fields
.btn.form-button {
  height: calc(1.5em + 0.75rem + 7px);
}

//This is a rule for Safari because the fields on add medication had a border around them
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    div[type="button"] {
      -webkit-appearance: button-bevel !important;
    }
  }
}

a.text-light {
  &:focus {
    color: var(--graydark2) !important;
  }

  &:hover {
    color: var(--graydark2) !important;
  }
}

//A rule for the calendar selector to make the border and SVG blue when hovered
// button.btn.btn-filter:focus {
//   svg path[id="Line_391"],
//   svg path[id="Line_392"] {
//     stroke: var(--primary);
//   }
//   position: relative;
//   &:after {
//     content: "";
//     position: absolute;
//     left: 0;
//     right: 0;
//     bottom: -0.25rem;
//     height: 0;
//     border: 1px solid var(--primary);
//     background: var(--primary);
//     border-radius: 5px;
//   }
// }

button.btn:not(.btn-link):not(.btn-text-danger):not(.btn-filter):not(
    .focusable-text
  ):not(.btn-add):not(.btn-small) {
  @include media-breakpoint-up(md) {
    @include smart-theme(9rem, 7rem, min-width);
  }

  @include media-breakpoint-down(sm) {
    // @include smart-theme(21px, 16px, font-size);
    width: 100% !important;
  }
}

a.btn:not(.btn-link):not(.btn-xs):not(.nav-item) {
  @include media-breakpoint-down(sm) {
    // @include smart-theme(21px, 16px, font-size);
    width: 100% !important;
  }
}

// button.w-75 {
//   // font-weight: 500;
//   font-size: 21px;
// }
.btn-add {
  white-space: nowrap;
  width: 120px;
  border-top-left-radius: 0%;
  border-bottom-left-radius: 0%;
}

.btn.focusable-text {
  font-size: 1rem;
  padding: 0;
  margin: 0;

  @media all and (-ms-high-contrast: none) {
    word-break: break-all;
  }

  cursor: default !important;

  &:hover {
    color: var(--graydark2);
  }
}

button.btn-outline:focus {
  svg path {
    stroke: var(--primary);
  }

  h4 {
    color: var(--primary) !important;
  }
}

#frequency-label,
#quantity-label,
#instructions-label {
  &:focus {
    span {
      color: $navy !important;
    }

    svg path {
      stroke: var(--dark);
    }
  }
}

.text-graydark1 .btn.focusable-text {
  color: var(--graydark1);

  &:hover {
    color: var(--graydark2);

    svg path {
      stroke: var(--graydark2);
    }
  }
}

.btn-white {
  @include make-accessible(var(--strong), var(--dark-primary), color);

  &:hover,
  &:focus {
    color: var(--dark-primary);
    background-color: var(--gray);
  }
}

a.btn.btn-outline-white {
  border-width: 2.5px;
}

.btn-outline-graydark1:focus {
  border-color: var(--graydark2);
  color: var(--graydark2);
}

.btn-gradient {
  @extend .btn-primary;
  background: linear-gradient(90.85deg, #1da1f2 8.02%, var(--strong) 96.37%);
  border: transparent;

  &:hover,
  &:focus {
    background: linear-gradient(90.85deg, #1da1f2 3.02%, var(--strong) 80.37%);
  }

  &:disabled {
    color: var(--info);
    background: var(--light);
    opacity: 1;
  }
}

.btn.btn-gradient.form-button {
  @extend .btn-primary;

  &.active.selected {
    border-color: var(--light-primary) !important;
  }
}

.btn:hover {
  svg.glyph-dark {
    line,
    rect,
    path {
      stroke: var(--graydark2);
    }
  }
}

.btn-link.btn-secondary:not(:disabled):not(.disabled):active,
.btn-link.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-link.btn-secondary.dropdown-toggle {
  color: var(--secondary);

  &:hover {
    color: var(--secondary);
  }
}

#filter-button:focus {
  .primary-underline:after {
    background: var(--extra-light-primary);
    border: 1px solid var(--extra-light-primary);
  }

  .glyph-gray {
    path {
      stroke: var(--extra-light-primary);
    }
  }
}

.filter-btn {
  min-width: 9rem;
}

#Account-toggle:focus .e-avatar {
  outline: 2px solid var(--extra-light-primary) !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
  border-radius: 50% !important;
}

.underline {
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: underline !important;
  }
}

#attach-file {
  margin-left: -2px;
  // 0 top left and bottom left border radius
  border-radius: 0 $border-radius $border-radius 0;
}

.btn.btn-dropdown {
  width: 6rem !important;
  min-width: 6rem !important;
  padding: 0.25rem;
  text-align: center;
  color: white;

  &:hover,
  &:focus {
    color: white;
  }

  &:disabled,
  &.disabled,
  &.dropdown-item:disabled,
  &.dropdown-item.disabled {
    color: var(--info) !important;
  }
}

.btn-square {
  min-width: 22% !important;
  padding: 0.25rem;
}

.btn-white.text-secondary,
.bg-white {
  &.active.selected {
    background-color: white;
    cursor: default;

    span {
      color: var(--light-primary);
    }

    border-color: var(--light-primary) !important;

    .glyph-gray-fill {
      path {
        fill: var(--light-primary);
      }
    }
  }
}
