//Badges
.badge-danger {
  @include make-accessible(var(--danger), var(--dark-danger), color);
  background-color: white;
  border: solid 2px var(--danger);
}

.badge-warning {
  @include make-accessible(var(--warning), var(--dark-warning), color);
  background-color: white;
  border: solid 2px var(--warning);
}

.badge-success {
  color: var(--primary);
  background-color: white;
  border: solid 2px var(--primary);
}
.badge-info {
  border: 2px solid white;
}

.badge-primary {
  background-color: var(--primary);
}
