.custom-checkbox .custom-control-label::before {
  background-color: transparent;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: white;
  background-color: transparent;
}

.custom-control-label::after {
  width: 1rem;
  height: 1rem;
  top: 0.225rem;
}
.custom-control-label::before {
  border: 2px solid white;
  width: 1rem;
  height: 1rem;
  top: 0.225rem;
}

.custom-radio .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border: 2px solid var(--dark);
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23'/%3e%3c/svg%3e");
}

#instructions-options label {
  input[type="radio"] {
    width: 0;
    height: 0;
  }
  span {
    position: relative;
    background-color: white;
    // left: -13px;
    display: inline-block;
    color: var(--graydark2);
    border: 2px solid var(--graydark1);
    border-radius: 3px;
    width: 130px;
    margin: 0.5rem 1rem 0 0;
    padding: 0.25rem;
    text-align: center;
    cursor: pointer;
    &.disabled {
      cursor: default;
      background-color: #dad6d6;
    }
  }
}

#instructions-options {
  input[type="radio"]:checked + span {
    color: var(--primary);
    border-color: var(--primary);
  }
  input[type="radio"]:focus + span {
    color: var(--graydark2);
    border-color: var(--extra-light-primary);
  }
}

.patient-select {
  input[type="checkbox"]:focus + span {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}

.accessibility .custom-radio .custom-control-label::before,
.accessibility
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::before {
  border: 2px solid var(--dark);
  background-color: var(--gray);
}
.custom-control-label {
  cursor: pointer;
}
.custom-control-label[for="medSelectAll"] {
  white-space: nowrap;
}
.accessibility
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%231f2f36'/%3e%3c/svg%3e");
}

.dark-checkmark {
  .custom-control-label::before,
  .custom-control-input:checked ~ .custom-control-label::before {
    border: 2px solid var(--dark);
    width: 0.85rem;
    height: 0.85rem;
    border-radius: 3px;
    @include large-text(3px, 5px, top);
  }
  .custom-control-label::after {
    height: 0.85rem;
    width: 0.85rem;
  }
  .custom-control-input:checked ~ .custom-control-label::after {
    @include make-accessible(var(--dark), var(--dark-dark), background-color);
    border-radius: 3px;
    background-image: url("../../images/glyph_check-light.png");
  }
}

.blue-checkmark {
  .custom-control-label::before,
  .custom-control-input:checked ~ .custom-control-label::before {
    border: 2px solid var(--primary);
    width: 0.85rem;
    height: 0.85rem;
    border-radius: 3px;
  }
  .custom-control-label::after {
    height: 0.85rem;
    width: 0.85rem;
  }
  .custom-control-input:checked ~ .custom-control-label::after {
    @include make-accessible(var(--primary), var(--primary), background-color);
    border-radius: 3px;
    background-image: url("../../images/glyph_check-light.png");
  }
}

.light-blue-checkmark {
  .custom-control-label::before,
  .custom-control-input:checked ~ .custom-control-label::before {
    border: 2px solid var(--graydark1);
    width: 0.85rem;
    height: 0.85rem;
    border-radius: 3px;
  }
  .custom-control-label::after {
    height: 0.85rem;
    width: 0.85rem;
  }
  .custom-control-input:checked ~ .custom-control-label::after {
    @include make-accessible(
      var(--light-primary),
      var(--light-primary),
      background-color
    );
    border-radius: 3px;
    background-image: url("../../images/glyph_check-light.png");
  }
}

.gray-checkmark {
  .custom-control-label::before,
  .custom-control-input:checked ~ .custom-control-label::before {
    border: 2px solid var(--secondary);
    width: 0.85rem;
    height: 0.85rem;
    border-radius: 3px;
  }
  .custom-control-label::after {
    height: 0.85rem;
    width: 0.85rem;
  }
  .custom-control-input:checked ~ .custom-control-label::after {
    background-color: var(--secondary);
    border-radius: 3px;
    background-image: url("../../images/glyph_check-light.png");
  }
}
.custom-switch {
  padding-left: 3rem;
  margin-top: -4px;
  .custom-control-label::before {
    border: 1px solid var(--graydark2);
    top: 0.2rem;
    background-color: var(--graydark2);
    left: -3.05rem;
    width: 2.5rem;
    pointer-events: all;
    border-radius: 1.25rem;
    height: 1.25rem;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: var(--primary);
    background-color: var(--primary);
    background-image: url("../../images/glyph_check-light.png");
    background-position: 20% center;
    background-repeat: no-repeat;
    background-size: 8px;
  }

  .custom-control-label::after {
    background-color: white;
    top: calc(0.2rem + 2px);
    left: calc(-3rem + 1.25px);
    width: calc(1.25rem - 4px);
    height: calc(1.25rem - 4px);
    border-radius: 100%;
  }
}
.g-table-checkbox {
  display: inline-block;
  // cursor: pointer;
  @include large-text(1rem, 1.125rem, font-size);

  position: relative;
  padding-left: 1.5rem;
  @include make-accessible(var(--dark), var(--dark-dark), color);
}

/* Hide the browser's default checkbox */
.g-table-checkbox input,
.g-table-star input,
.g-table-circle-check input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  cursor: pointer;
  position: absolute;
  @include large-text(1px, 2px, top);
  left: 0;
  height: 14px;
  width: 14px;
  border: 2px solid var(--dark);
  border-radius: 2px;
}

#manage-invites {
  .checkmark {
    @include large-text(4px, 6px, top);
  }
  td[role="cell"] .checkmark {
    @include large-text(-6px, -7px, top);
  }
}

#user-invites {
  .checkmark {
    @include large-text(4px, 5px, top);
  }
  td[role="cell"] .checkmark {
    @include large-text(-6px, -7px, top);
  }
}

.radio {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: -0.5rem;
  height: 14px;
  width: 14px;
  border: 2px solid var(--graydark2);
  border-radius: 50%;
}
.g-table-checkbox input[readonly] ~ .radio {
  cursor: default !important;
}
.pointer:focus .checkmark {
  border-color: var(--primary);
}

td[role="cell"] .checkmark {
  @include large-text(-6px, -7px, top);
}
/* When the checkbox is checked, add a blue background */
.g-table-checkbox input:checked ~ .checkmark {
  @include make-accessible(var(--dark), var(--dark-dark), background-color);
}

.g-table-checkbox input:focus ~ .checkmark {
  border-color: var(--primary);
}

.g-table-checkbox input:disabled ~ .checkmark {
  border-color: var(--graydark1);
  cursor: default;
}
.g-table-checkbox.text-graydark1 {
  cursor: default;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after,
.radio:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.g-table-checkbox input:checked ~ .checkmark:after {
  display: block;
}
/* Show the radio circle when checked */
.g-table-checkbox input:checked ~ .radio:after {
  display: block;
}

/* Style the checkmark/indicator */
.g-table-checkbox .checkmark:after {
  left: 3px;
  top: -1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* Style the radio/indicator */
.g-table-checkbox .radio:after {
  left: 1px;
  top: 1px;
  width: 8px;
  height: 8px;
  border: solid var(--graydark2);
  border-radius: 50%;
  border-width: 4px;
  // -webkit-transform: rotate(45deg);
  // -ms-transform: rotate(45deg);
  // transform: rotate(45deg);
}
.g-table-star {
  @include media-breakpoint-down(mdlg) {
    padding-top: 0.25rem;
  }
  // when focus on nested input, add a box-shadow to g-table-star
  input:focus ~ .star {
    box-shadow: 0 0 0 0.2rem rgba(0, 94, 131, 0.25);
    border-radius: $border-radius;
  }
  display: inline-block;
  input ~ .star {
    cursor: pointer;
  }
  input:disabled ~ .star {
    cursor: default;
  }
  position: relative;
  margin-top: 0.25rem;
  // padding-left: 22px;
  @include make-accessible(var(--dark), var(--dark-dark), color);
  margin-bottom: 0;
  .star {
    // background: url("../../images/glyph-star.svg") no-repeat;
    width: 16px;
    height: 16px;
  }
  input:checked ~ .star {
    path,
    line {
      @include make-accessible(var(--primary), var(--dark-primay), fill);
      @include make-accessible(var(--primay), var(--dark-primary), stroke);
    }
  }

  // &:hover {
  //   .star {
  //     path,
  //     line {
  //       fill: var(--primary);
  //       stroke: var(--primary);
  //     }
  //   }
  //   input:checked ~ .star {
  //     path,
  //     line {
  //       fill: var(--graydark1);
  //       stroke: var(--graydark1);
  //     }
  //   }
  // }
  // &:focus {
  //   .star {
  //     path,
  //     line {
  //       fill: var(--primary);
  //       stroke: var(--primary);
  //     }
  //   }
  //   input:checked ~ .star {
  //     path,
  //     line {
  //       fill: var(--graydark1);
  //       stroke: var(--graydark1);
  //     }
  //   }
  // }
  @include media-breakpoint-down(sm) {
    padding-left: 0;
  }
}

.g-table-circle-check {
  @include media-breakpoint-down(mdlg) {
    padding-top: 0.25rem;
  }

  display: inline-block;
  cursor: pointer;
  position: relative;
  margin-top: 0.25rem;
  // padding-left: 22px;
  @include make-accessible(var(--dark), var(--dark-dark), color);
  margin-bottom: 0;
  .circle-check {
    background: url("../../images/circle_unfilled_check.png") no-repeat;
    width: 32px;
    height: 32px;
  }
  input:checked ~ .circle-check {
    background: url("../../images/circle_filled_check.png") no-repeat;
  }
  // input:focus + .circle-check {
  //   background: url("../../images/circle_unfilled_check_hover.svg") no-repeat;
  // }
  @include media-breakpoint-down(sm) {
    padding-left: 0;
  }
  // &:hover {
  //   .circle-check {
  //     background: url("../../images/circle_unfilled_check_hover.svg") no-repeat;
  // //   }
  //   input:checked ~ .circle-check {
  //     background: url("../../images/circle_filled_check_hover.svg") no-repeat;
  //   }
  // }
  // input:checked:focus + .circle-check {
  //   background: url("../../images/circle_filled_check_hover.svg") no-repeat;
  // }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(1.2rem);
}

.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: transparent;
  border: 2px solid var(--graydark1) !important;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 94, 131, 0.25);
  border-color: #80bdff;
}

.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: var(--graydark1);
  border: 2px solid var(--secondary);
}

.custom-radio .custom-control-input:disabled ~ .custom-control-label {
  cursor: default;
}

.with-x.custom-switch {
  .custom-control-label::before {
    background-image: url("../../images/glyph_x_white.png");
    background-position: 80% center;
    background-color: $navy-light1;
    background-repeat: no-repeat;
    background-size: 8px;
  }
}
