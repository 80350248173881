.tooltip,
.popover {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
}

.popover {
  min-width: 220px;
  max-width: 400px;

  @include media-breakpoint-down(sm) {
    max-width: 350px;
  }

  @media screen and (max-width: 321px) {
    max-width: 310px;
  }

  margin-top: 20px;
  margin-left: -20px;

  .popover-body table {
    th,
    td {
      padding: 0.25rem;
    }

    td:first-of-type,
    th:first-of-type {
      padding-left: 0px;
    }

    td:last-of-type,
    th:last-of-type {
      padding-right: 0;
    }
  }

  .popover-body table.table-borderless {
    td,
    th {
      padding: 0.25rem;
    }

    td:first-of-type,
    th:first-of-type {
      padding-left: 22px;
    }

    td:last-of-type,
    th:last-of-type {
      padding-right: 0;
    }

    tr:last-child {
      border: none;
    }
  }
}

.popover-header {
  min-height: 1.75rem;
  padding: 0.5rem 0.75rem;
  @include large-text(1rem, 1.125rem, font-size);
  @include make-accessible($gray-light2 !important, white !important, color);
}

.popover-body,
.tooltip-inner {
  @include large-text(1rem, 1.125rem, font-size);
}

.tooltip-inner {
  text-align: left;
  max-width: 350px;
}

#group-popover {
  width: 220px;

  .popover-body {
    padding: 1rem 2rem;
  }
}

#sent-via {
  max-width: 500px;
}

.popover[x-placement="bottom-end"] {
  left: 5rem !important;
  top: 4px !important;
}

.groups-popover {
  max-width: 10rem;

  @include media-breakpoint-down(mdlg) {
    max-width: 5rem;
  }

  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;

  &:hover {
    color: var(--graydark2);

    span {
      color: var(--graydark2);
    }

    svg path,
    svg rect {
      stroke: var(--graydark2);
    }
  }

  &.gray-glyph-possible {
    @include media-breakpoint-down(sm) {
      color: var(--graydark2);

      &:hover {
        color: var(--secondary);

        svg:not(.glyph-light-gray) {
          path,
          rect {
            stroke: var(--secondary);
          }
        }
      }

      svg:not(.glyph-light-gray) {
        path,
        rect {
          stroke: var(--graydark2);
        }
      }
    }
  }
}

.text-graydark1 {
  .gray-glyph-possible {
    @include media-breakpoint-down(sm) {
      color: var(--graydark1);

      &:hover {
        color: var(--graydark2);
      }

      svg:not(.glyph-light-gray) {
        path,
        rect {
          stroke: var(--graydark1);
        }
      }
    }
  }
}

// span:not(.text-graydark1) .gray-glyph-possible {
//   color: var(--graydark2);
//   &:hover {
//     color: var(--secondary);
//     svg path,
//     svg rect {
//       stroke: var(--secondary);
//     }
//   }
// }
.sentvia-popover {
  text-align: left;
  cursor: default !important;

  &:hover {
    color: var(--graydark2) !important;

    span {
      color: var(--graydark2);
    }

    svg path,
    svg rect {
      stroke: var(--graydark2);
    }
  }

  &:focus {
    svg path,
    svg rect {
      stroke: var(--graydark2);
    }
  }
}

.group-popover.popover-body {
  word-break: break-all;
  padding: 1rem 35px;

  tbody tr td:nth-of-type(2) {
    width: 50%;
  }
}

.width-275.fade {
  transition: opacity 0s linear !important;
}

.react-datepicker-popper {
  z-index: 100 !important;
}

@include media-breakpoint-down(sm) {
  .popover {
    margin-left: 0 !important;
  }
}

.med-event-modal table {
  th,
  td {
    padding: 0.25rem;
  }
}

.med-event-modal .modal-title {
  font-size: 16px;
  @include make-accessible(var(--dark), var(--dark-dark), color);
  width: 100%;
}

.resend-tooltip {
  .popover {
    min-width: 0 !important;
    max-width: 130px;
    margin-top: 40px;
  }
}

.quality-measures-popover .popover {
  width: 400px;
}

.language-popover .popover {
  min-width: 2rem;

  .popover-body {
    padding: 0.75rem 1.5rem;
  }
}

.dose-popover .popover {
  width: 300px;
}
