.carousel__dot-group {
  margin-bottom: 1.5rem;
  text-align: center;
}

.carousel__dot {
  padding: 0.25rem;
  border-radius: 50%;
  background-color: var(--graydark1);
  border: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;

  &.carousel__dot--selected {
    background-color: var(--graydark2);
  }
}

.focusRing___1airF.carousel__slide-focus-ring {
  outline: none !important;
}

.carousel-control-prev-icon {
  background-image: url("../../images/chevron_left_gray.png");
  width: 12px;
  height: 12px;
}
.carousel-control-next-icon {
  background-image: url("../../images/chevron_right_gray.png");
  width: 12px;
  height: 12px;
}

.carousel-control-prev, .carousel-control-next {
  position: relative;
}