//
.sidebar {
  min-height: calc(100vh - 74px);
  background: white;
  @include media-breakpoint-down(xl) {
    min-width: 52px;
    max-width: 52px;
    .list-group-item {
      padding: 0.75rem 0.8rem;
    }
    min-height: calc(100vh - 52px);
  }

  @include media-breakpoint-up(xxl) {
    min-width: 17.5rem;
    max-width: 17.5rem;
    .list-group-item {
      padding: 0.75rem calc(2.5rem + 2px);
    }
  }
  .divider-padding {
    margin-top: 29.8px;
  }

  .list-group-item {
    svg {
      width: 24px;
    }
    @include large-text(3.75rem, 4rem, height);
    display: flex;
    align-items: center; // padding: 1.125rem 1.25rem;
    &:not(.active) a {
      @include make-accessible(var(--dark), var(--dark-dark), color);
      @include large-text(1.125rem, 1.25rem font-size);
      svg path,
      circle,
      ellipse {
        @include make-accessible(var(--dark), var(--dark-dark), stroke);
      }
    }

    &:not(.active) a:hover {
      color: var(--primary);
      svg path,
      svg circle,
      svg ellipse {
        stroke: var(--primary);
      }
    }

    &.active,
    &.active:hover {
      background-color: $navy-light2;
      border-radius: 0;
      a {
        color: white;
        svg path,
        svg circle,
        svg ellipse {
          stroke: white;
        }
      }
      // a,
      a:hover {
        color: white;
        svg path,
        svg circle,
        svg ellipse {
          stroke: white;
        }
      }
    }
  }
}

.topbar {
  .custom-collapse {
    height: 30px;
    transition: height 0.2s ease;
    svg,
    .nav-line {
      transition: opacity 0.2s ease;
      opacity: 0;
    }
    &.show {
      svg,
      .nav-line {
        opacity: 1;
      }
      height: 52px;
    }
  }

  position: sticky;
  top: 52px;

  z-index: 799;
  .list-group-item.active {
    background-color: transparent !important;
    margin-top: 0;
    a {
      svg path,
      svg circle,
      svg ellipse {
        stroke: var(--primary);
      }
    }
  }
  .list-group-item {
    background-color: transparent;
    padding: 0.75rem 0.75rem;
  }
}

.scrollable {
  overflow-y: auto;
  @media all and (-ms-high-contrast: none) {
    overflow-y: scroll;
  }
}

#admin-center,
#profile-center,
#invitation-center {
  .e-sidebar {
    min-height: calc(100vh - 52px);
    position: fixed;
    top: 52px;
    &.smart-sidebar {
      top: 48px;
      @media all and (-ms-high-contrast: none) {
        top: 0;
        z-index: 1;
        padding-top: 2.5rem;
      }
    }
  }
}
.e-sidebar {
  @include media-breakpoint-up(lg) {
    @include make-accessible(var(--dark), var(--dark-dark), background-color);
  }
  .nav-link {
    border: 1px solid transparent;
    //    @include make-accessible(var(--dark), var(--dark-dark), border-color);

    color: white;
    margin: 0.25rem 0 0.25rem 0;
    &.active,
    &:focus {
      background-color: $navy-light2;
      color: white !important;
      border: 1px solid transparent;
      border-radius: 5px;
    }
  }
}
#permanent-sidebar {
  @include media-breakpoint-down(mdlg) {
    display: none;
  }
  z-index: 500 !important;
  width: 240px !important;
  /*CSS transitions*/
  -o-transition-property: none !important;
  -moz-transition-property: none !important;
  -ms-transition-property: none !important;
  -webkit-transition-property: none !important;
  transition-property: none !important;
  /*CSS transforms*/
  -o-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -webkit-transform: none !important;
  transform: none !important;
  /*CSS animations*/
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
  //  width: 220px !important;
  visibility: visible !important;
}
.e-content-animation {
  transition: margin 0s ease, transform 0s ease;
}

#notifications,
.notification {
  max-width: 410px !important;
}

#permanent-sidebar-fallback {
  flex: 0 0 240px;
}
