.dropdown-menu {
  border: none;
  box-shadow: 0 2px 5px 0 rgba(12.2, 18.4, 21.2, 0.16);
  border-radius: $border-radius;
  padding-bottom: 2rem;
  z-index: 999;

  &:focus {
    box-shadow: 0 2px 5px 0 rgba(12.2, 18.4, 21.2, 0.16) !important;
  }
}

.dropdown-divider {
  border-top: 2px solid var(--gray);
}

.list-group-item {
  border: none;
  white-space: nowrap;

  &:hover {
    background-color: transparent;
  }
}

.list-group-item.disabled {
  color: var(--graydark1) !important;
}

a.text-secondary:not(.btn):hover {
  color: $navy !important;

  svg path {
    stroke: var(--dark);
  }
}

.dropdown-item:not(.btn-primary) {
  svg {
    path,
    line,
    circle,
    rect {
      stroke: var(--dark);
    }
  }

  cursor: pointer;
  @include large-text(1rem, 1.125rem, font-size);
  @include make-accessible(var(--dark), var(--dark-dark), color);
  padding: 0.5rem 1.55rem;

  &:hover,
  &:focus {
    outline: 0 !important;
    color: var(--primary);
    background-color: white;

    .text-danger {
      @include make-accessible(
        var(--dark-danger) !important,
        $red-dark2 !important,
        color
      );
    }
  }

  &.active,
  &.selected {
    @include make-accessible(var(--graydark2), var(--secondary), color);
    background-color: white;

    svg {
      path,
      line,
      circle,
      rect {
        @include make-accessible(var(--graydark2), var(--secondary), fill);
      }
    }
  }

  .text-primary {
    &:hover,
    &:focus {
      color: var(--primary);
    }
  }
}

//Dropdowns on tables
.dropdown-menu:not(.Account):not(.notification):not(.Support):not(.Admin):not(
    .header-filter
  ):not(.Settings):not(.Invitations):not(.frequency):not(.prnReason):not(
    .doseType
  ):not(.therapeuticClass) {
  margin-top: 0.6rem;
  @include large-text(-0.5rem, -1.75rem, margin-left);
  // margin-left: -0.5rem;
  margin-bottom: 1rem;
  // z-index: 1;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;

  .dropdown-item:not(.nav-dropdown-item):not(.btn-dropdown) {
    @include make-accessible(var(--dark), var(--dark-dark), color);

    &:hover,
    &:focus,
    &:active {
      color: var(--primary);
    }
  }

  &.dropdown-menu-right {
    transform: translate3d(-170px, -60px, 0px) !important;
  }
}

@media (max-width: 768px) {
  .dropdown-menu:not(.Account):not(.notification):not(.Support):not(.Admin):not(
      .header-filter
    ):not(.Settings):not(.Invitations):not(.frequency):not(.prnReason):not(
      .doseType
    ):not(.therapeuticClass) {
    @include large-text(-1rem, -1.75rem, margin-left);
  }
}

.Settings,
.Invitations {
  margin-top: 0.6rem;
  margin-left: 0.5rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.Settings {
  min-width: 12.5rem;
  margin-left: 0;
}

table tr:last-child {
  .dropdown-menu:not(.Account):not(.notification):not(.Support):not(.Admin):not(
      .header-filter
    ):not(.Settings).dropdown-menu-right {
    transform: translate3d(-170px, -80px, 0px) !important;
  }
}

table tr:first-child {
  .dropdown-menu:not(.Account):not(.notification):not(.Support):not(.Admin):not(
      .header-filter
    ):not(.Settings).dropdown-menu-right {
    transform: translate3d(-170px, -40px, 0px) !important;
  }
}

table tr:first-child:last-child {
  .dropdown-menu:not(.Account):not(.notification):not(.Support):not(.Admin):not(
      .header-filter
    ):not(.Settings).dropdown-menu-right {
    transform: translate3d(-170px, -60px, 0px) !important;
  }
}

#reports-table,
#manage-invites {
  .header-filter.show {
    // margin-left: -2rem;
    max-height: 50vh;
    overflow-y: auto;

    label {
      white-space: pre-wrap;
    }
  }
}

.dropdown-menu.date-filter {
  margin-left: -2rem !important;
}

.dropdown-header {
  text-align: left;
  font-size: 21px;
  font-weight: 500;
  color: $dark;

  &.small {
    @extend .small;
  }
}

.dropdown-menu.Account {
  animation-name: slidedown;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  @include large-text(1rem, 0.9rem, margin-top);

  @include large-text(0.4rem, 0.3rem, margin-top);

  .dropdown-header {
    @include large-text(1rem 1.75rem 0.05rem, 0.9rem 1.75rem 0.05rem, padding);

    @include make-accessible(var(--dark), var(--dark-dark), color);
  }
}

.dropdown-menu.Support {
  .dropdown-header {
    @include large-text(
      1.75rem 2.25rem 0.75rem,
      1.75rem 2.25rem 0.95rem,
      padding
    );
    @include make-accessible(var(--dark), var(--dark-dark), color);
  }
}

.dropdown-item.disabled:not(.text-dark) {
  color: var(--graydark1) !important;
}

.dropup .dropdown-toggle:after {
  display: none;
}

#user-invites,
#group-users {
  .dropdown-menu {
    margin-left: -0.6rem !important;
  }
}

.dropdown-menu.Support,
.dropdown-menu.Admin,
.dropdown-menu.Patients,
.dropdown-menu.Invitations {
  animation-name: slidedown;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

.dropdown-menu.show.Support {
  @include large-text(0.7rem, 0.75rem, margin-top);

  transform: translateY(38px) !important;

  min-width: 250px;
}

// .dropdown-menu.show.Patients,
.dropdown-menu.show.Invitations {
  transform: translateY(35px) !important;
  // margin-top: 0.15rem;
}

.dropdown-menu.show.Account {
  min-width: 300px;

  .dropdown-item:not(.disabled) {
    padding-left: 2.25rem;
  }

  // margin-left: -1.5rem;
  // @include large-text(-11.5rem, -11.4rem, margin-left);
}

@keyframes slidedown {
  from {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
  }

  to {
    opacity: 1;
    // max-height: 300px;
  }
}

.notification {
  animation-name: slidedown;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  width: 435px;
  margin-left: -10rem;

  &.smart-notifications {
    margin-left: -20rem;
  }

  // @include media-breakpoint-down(xxl) {
  //   margin-left: -10rem;
  // }
  // @include media-breakpoint-up(xxl) {
  //   margin-left: -10rem;
  // }
}

.notification.show {
  padding-bottom: 0;
  transform: translateY(2rem) !important;

  &.smart-notifications {
    transform: translateY(1.8rem) !important;
  }
}

.notification.show #notification-dropdown {
  max-height: 75vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.nav-link {
  cursor: pointer;

  &:hover,
  &:focus {
    color: var(--graydark2);

    svg {
      path,
      line,
      circle,
      rect {
        stroke: var(--dark);
      }
    }

    svg.glyph-dark path,
    svg.glyph-dark line,
    svg.glyph-link-blue path {
      stroke: var(--graydark2);
    }

    svg.glyph-white path {
      stroke: var(--graydark1);
    }
  }
}

.select-normal {
  @include large-text(1rem, 1.125rem, font-size);
}

.dropdown-menu.show.Sidebar {
  margin-top: -0.5rem;
  margin-left: -1rem;
  padding-bottom: calc(100vh - 280px);
}

.Sidebar,
.Admin-Sidebar,
.Profile-Sidebar {
  padding-left: 1rem !important;
  padding-right: 1rem !important;

  a,
  button {
    color: #1f2f36 !important;

    svg path,
    svg circle,
    svg ellipse {
      stroke: #1f2f36;
    }
  }

  a:hover,
  button:hover {
    color: var(--primary) !important;

    svg path,
    svg circle,
    svg ellipse {
      stroke: var(--primary);
    }
  }

  .dropdown-item {
    padding-left: 1.25rem;
  }
}

.Support .dropdown-item {
  @include media-breakpoint-down(mdlg) {
    padding-left: 1.5rem;
  }
}

.dropdown-menu.show.Admin-Sidebar {
  margin-top: -0.5rem;
  margin-left: -1rem;
  padding-bottom: calc(100vh - 240px);
}

.dropdown-menu.show.Profile-Sidebar {
  margin-top: -0.5rem;
  margin-left: -1rem;
  padding-bottom: calc(100vh - 240px);
}

.Account .close,
.Support .close {
  padding-right: 1rem;
}

.Account:not(.dropdown-menu) .dropdown-item:not(.Mui-disabled) {
  padding-left: 4.5rem;
}

.Account .dropdown-item.Mui-disabled {
  padding-left: 1.25rem;
}

.MuiListItem-root.Mui-disabled {
  opacity: 1 !important;
  cursor: default;
}

.MuiDrawer-paper {
  //for IE
  overflow: hidden;

  @include media-breakpoint-up(md) {
    top: 52px !important;
  }
}

.MuiButton-root {
  min-width: 38px !important;
}

.dropdown-item.health-trackers.active {
  background-color: rgba(var(--rgb-primary), 0.15);
  color: var(--primary) !important;
}

.export .dropdown-menu {
  .dropdown-item {
    @include large-text(1rem, 1.125rem, font-size);
    color: var(--primary) !important;

    &:hover,
    &.active {
      color: var(--dark-primary) !important;
    }

    &:focus {
      color: var(--dark-primary) !important;
    }
  }
}

.dropdown-link {
  @include make-accessible(var(--dark), var(--dark-dark), color);

  &:hover,
  &:focus {
    color: var(--primary);
  }
}

.Settings.dropdown-menu .dropdown-item:hover {
  svg {
    path,
    line,
    polyline,
    ellipse {
      stroke: var(--primary) !important;
    }
  }
}

.Settings.dropdown-menu {
  left: 1rem !important;
  animation-name: slidedown;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

.notification-hover-section {
  cursor: pointer;

  .mark-as-read {
    width: 8px;
    height: 8px;
    background: var(--light-primary);
    border-radius: 50%;
  }

  .mark-as-unread {
    width: 8px;
    height: 8px;

    // background: #edeeef;
    border-radius: 50%;
  }

  &:hover {
    .notification-body {
      text-decoration: underline;
    }

    background-color: $gray;

    .mark-as-read,
    .mark-as-unread {
      outline: 1px solid $gray-dark1;
      outline-offset: 0.5rem;
    }

    .btn.btn-link.align-self-start:hover {
      .mark-as-read,
      .mark-as-unread {
        outline: 0.5rem solid $gray-dark1;
        outline-offset: 0;
      }

      .mark-as-unread {
        background: $gray-dark1;
      }
    }
  }
}

.notification-hover-section-smart {
  cursor: default;

  .mark-as-read {
    cursor: pointer;

    width: 8px;
    height: 8px;
    background: var(--light-primary);
    border-radius: 50%;
  }

  .mark-as-unread {
    cursor: pointer;

    width: 8px;
    height: 8px;

    // background: #edeeef;
    border-radius: 50%;
  }

  &:hover {
    .mark-as-read,
    .mark-as-unread {
      outline: 1px solid $gray-dark1;
      outline-offset: 0.5rem;
    }

    .btn.btn-link.align-self-start:hover {
      .mark-as-read,
      .mark-as-unread {
        outline: 0.5rem solid $gray-dark1;
        outline-offset: 0;
      }

      .mark-as-unread {
        background: $gray-dark1;
      }
    }
  }
}

.custom-dropdown {
  cursor: pointer;

  // show ellipsis when text is too long
  // text-overflow: ellipsis;
  // overflow: hidden;
  // white-space: nowrap;
  background-color: var(--gray);

  &:focus,
  &:hover,
  &:focus-within {
    border-color: var(--extra-light-primary);
    background-color: var(--gray);
  }

  &.disabled {
    input {
      cursor: default;
    }

    cursor: default;
    background-color: var(--graydark1);
    border-color: var(--graydark1);

    svg path {
      stroke: var(--secondary) !important;
    }
  }

  .overflow-hidden {
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  input:focus-visible {
    outline: none;
    box-shadow: none;
  }
}

.expandable-dropdown {
  border: 2px solid var(--graydark1);
  @include make-accessible(var(--dark), var(--dark-dark), color);
  border-radius: $border-radius;
  @include large-text(calc(2.25rem + 4px), calc(2.25rem + 8px), min-height);
  height: unset !important;

  &:focus {
    box-shadow: none;
  }

  input {
    border: none;
    background: none;
    margin: 0;

    max-width: 100%;
    cursor: pointer;
  }
}

.scrollable-dropdown-menu {
  max-height: 10rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.frequency,
.prnReason,
.doseType {
  width: 20rem;
  padding-bottom: 0.75rem;
  position: absolute !important;
  top: 100% !important;
  transform: translate3d(0, 0, 0) !important;
}

.frequency,
.doseType {
  left: 0 !important;
  padding-top: 0;
}

.prnReason {
  right: 0 !important;
  left: auto !important;
  padding-top: 0.75rem;
}

.frequency-table {
  width: 20rem;
}

.therapeuticClass {
  left: 0 !important;
  position: absolute !important;
  top: 100% !important;
  transform: translate3d(0, 0, 0) !important;
  width: 100%;
  margin-top: 0 !important;
}

.dropdown-item {
  // for IE only
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    &:hover,
    &:active,
    &:focus {
      color: var(--primary);
      background-color: white;
    }
  }
}
