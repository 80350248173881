.h-14-rem {
  height: 14.28rem;
}

.height-40 {
  height: 40px;
}

.border-radius-normal {
  border-radius: $border-radius;
}

.width-1100 {
  width: 1100px;
}

.w-58 {
  width: 58%;
}

.mt-neg {
  margin-top: -1rem;
}
