.progress {
  background-color: var(--gray);
  border-radius: $border-radius !important;
  height: 0.5rem;
}

.csv-progress-bar {
  width: 90%;

  span {
    margin-top: 0.5rem;
    background-color: var(--gray) !important;
  }
}
