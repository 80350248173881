// .fe6xZVPr1kb0CJksW39zV {
//   margin-bottom: 0 !important;
//   @include large-text(1rem, 1.125rem, font-size);
//   font-weight: 500;
//   font-family: "Effra", sans-serif;
//   @include make-accessible(var(--dark), var(--dark-dark), color);
// }

// ._2oSbenaQCQZPXOD75Sj6nE {
//   border: 2px dashed var(--primary) !important;
// }

// .YpD9o6H9LydyYmaPWOwt {
//   background: transparent;
// }

// @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
//   input[type="range"] {
//     height: 70px;
//     margin-top: -1rem;
//     margin-left: 1rem;
//     display: inline;
//   }
// }

// ._2oSbenaQCQZPXOD75Sj6nE,
// ._2D3XigQoPKPCPCHAaOtqJP {
//   height: 250px !important;
// }

// ._1Fq_tZ_l7SwH_r_a_SNOtA ._1Ou1tAFc1VCD4TNa0XwE7z {
//   padding: 5% 0%;
// }

.e-avatar {
  @include make-accessible(var(--graydark1), var(--dark), background-color);

  font-family: "Effra", sans-serif;
  &.e-avatar-xlarge {
    font-size: 1.75rem;
  }
  &.e-avatar-xsmall {
    font-size: 0.5rem;
  }
  &.e-avatar-small {
    font-size: 0.75rem;
  }
}

// .blue-dotted-outline {
//   outline: 2px dashed var(--primary);
//   border-radius: 3px;
//   outline-offset: -56px;
// }

// .favicon-width {
//   width: 350px
// }
