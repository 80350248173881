// Pixel heights
.h-8px {
  height: 8px;
}

.h-7px {
  height: 7px;
}

.h-10px {
  height: 10px;
}

.h-12px {
  height: 12px;
}

.h-16px {
  height: 16px;
}

.h-18px {
  height: 18px;
}

.h-20px {
  height: 20px;
}

.h-25px {
  height: 25px;
}

.h-30px {
  height: 30px;
}

.h-40px {
  height: 40px;
}

.h-50px {
  height: 50px;
}

.h-250px {
  height: 250px;
}

// REM Heights
.h-1rem {
  height: 1rem;
}

.h-2rem {
  height: 2rem;
}

.h-2-3rem {
  height: 2.3rem;
}

.h-3rem {
  height: 3rem;
}

.h-10rem {
  height: 10rem;
}

.h-17rem {
  height: 17rem;
}

.h-28rem {
  height: 28rem;
}

// View Heights
.h-60vh {
  height: 60vh;
}

.h-80vh {
  height: 80vh;
}

.h-100vh {
  height: 100vh;
}

.h-vh {
  height: calc(100vh - 52px);
}

// Percentage Heights
.h-93 {
  height: 93%;
}

// Min-heights/ Max-heights
.max-height-60vh {
  max-height: 60vh;
}

.min-height-70vh {
  min-height: 70vh;
}

.min-height-94vh {
  min-height: 94vh;
}

.min-h-2-75rem {
  min-height: 2.75rem;
}

.max-height-60vh {
  max-height: 60vh;
}

/* Pixel Widths */
.w-8px {
  width: 8px;
}

.w-10px {
  width: 10px;
}

.w-12px {
  width: 12px;
}

.h-15px {
  height: 15px;
}

.w-16px {
  width: 16px;
}

.w-18px {
  width: 18px;
}

.w-25px {
  width: 25px;
}

.w-185px {
  width: 185px;
}

.w-250px {
  width: 250px;
}

.w-300px {
  width: 300px;
}

.w-400px {
  width: 400px;
}

.w-600px {
  width: 600px;
}

/* Rem Widths */
.w-1rem {
  width: 1rem;
}

.w-3rem {
  width: 3rem;
}

.w-3-5rem {
  width: 3.5rem;
}

.w-4-5rem {
  width: 4.5rem;
}

.w-5-5rem {
  width: 5.5rem;
}

.w-7rem {
  width: 7rem;
}

.w-6rem {
  width: 6rem;
}

.w-12rem {
  width: 12rem;
}

.w-14rem {
  width: 14rem;
}

.w-15rem {
  width: 15rem;
}

.w-16rem {
  width: 16rem;
}

.w-30rem {
  width: 30rem;
}

.w-50rem {
  width: 50rem;
}

/* Percentage Widths */
.w-10 {
  width: 10%;
}

.w-28 {
  width: 28%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-44 {
  width: 44%;
}

.w-45 {
  width: 45%;
}

.w-60 {
  width: 60%;
}

/* Mins and Maxes */
.min-width-9-5rem {
  min-width: 9.5rem !important;
}

.min-width-9rem {
  @include large-text(9rem !important, 10rem !important, min-width);
}

.min-width-20rem {
  min-width: 20rem;
}

.max-width-50 {
  max-width: 50%;
}

.max-width-400px {
  max-width: 400px;
}

.max-width-500px {
  max-width: 500px;
}

.max-width-600px {
  max-width: 600px;
}

.max-width-660px {
  max-width: 660px;
}

// Margins
.my-neg-8px {
  margin-top: -8px;
  margin-bottom: -8px;
}

.mt-neg-8px {
  margin-top: -8px !important;
}

.mt-neg-8px {
  top: -8px !important;
}

.mt-neg-26rem {
  margin-top: -26rem;
}

.ml-3-75rem {
  margin-left: 3.75rem;
}

.ml-neg-4px {
  margin-left: -4px;
}

.ml-neg-14px {
  margin-left: -14px;
}

.ml-neg-0-2rem {
  margin-left: -0.2rem;
}

.ml-neg-22px {
  margin-left: -22px;
}

.mt-neg-0-5rem {
  margin-top: -0.5rem;
  min-width: 10rem !important;
}

.ml-neg-42px {
  margin-left: -42px;
}

.ml-neg-0-1rem {
  margin-left: -0.1rem;
}

.ml-neg-0-5rem {
  margin-left: -0.5rem;
}

.mr-neg-0-5rem {
  margin-right: -0.5rem;
}

.ml-neg-1-5rem {
  margin-left: -1.5rem;
}

.ml-neg-2rem {
  margin-left: -2rem !important;
}

.ml-neg-3rem {
  margin-left: -3rem;
}

.ml-neg-42px {
  margin-left: -42px;
}

.ml-neg-3-3rem {
  margin-left: -3.3rem;
}

.ml-neg-2-5rem {
  margin-left: -2.5rem;
}

.ml-neg-5rem {
  margin-left: -5rem;
}

.mt-neg-2px {
  margin-top: -2px;
}

.mb-neg-3rem {
  margin-bottom: -3rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-0-75rem {
  margin-bottom: 0.75rem;
}

.mt-neg-8px {
  margin-top: -8px;
}

.mt-neg-10px {
  margin-top: -10px;
}

.mt-neg-18px {
  margin-top: -18px;
}

.mt-neg-1rem {
  margin-top: -1rem;
}

.mt-neg-0-5rem {
  @include large-text(-0.5rem, -0.35rem, margin-top);
}

.mt-neg-7rem {
  @include media-breakpoint-up(xxl) {
    margin-top: -7rem;
  }

  @include media-breakpoint-only(xl) {
    margin-top: -8.2rem;
  }
}

.mb-neg-2-5rem {
  margin-bottom: -2.5rem;
}

.mb-neg-10px {
  margin-bottom: -10px;
}

.ml-2px {
  margin-left: 2px;
}

.ml-7px {
  margin-left: 7px;
}

.ml-75rem {
  margin-left: 0.75rem;
}

.mr-10px {
  margin-right: 10px;
}

.dynamic-margin {
  margin-left: var(--left-margin, 0%);
}

// Margin Percentages
.margin-7 {
  margin: 7% auto !important;
}

// padding
.padding-0-0-0-30px {
  padding: 0 0 0 30px;
}

.padding-0-0-0-42px {
  padding: 0 0 0 42px;
}

.padding-0-45-0-5px {
  padding: 0 45px 0 5px;
}

// White Space, wrapping, alignment
.white-space-prewrap {
  white-space: pre-wrap;
}

.white-space-nowrap {
  white-space: nowrap;
}

.vertical-align-middle {
  vertical-align: middle;
}

.table-layout-fixed {
  table-layout: fixed;
}

.break-word {
  word-break: break-word;
}

.word-wrap-normal {
  word-wrap: normal;
}

.line-break-anywhere {
  line-break: anywhere;
}

.cursor-style {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.word-break-normal {
  word-break: normal !important;
}

// Heights/Widths
.h8-w8-style {
  height: 10px;
  width: 10px;
}

.h12-w12-style {
  height: 12px;
  width: 12px;
}

.h14-w14-style {
  height: 14px;
  width: 14px;
}

.h16-w16-style {
  height: 16px;
  width: 16px;
}

.h18-w18-style {
  height: 18px;
  width: 18px;
}

.h12-w16-style {
  height: 12px;
  width: 16px;
}

.h22-w16-style {
  height: 22px;
  width: 16px;
}

.h20-w16-style {
  height: 20px;
  width: 16px;
}

.h20-w20-style {
  height: 20px;
  width: 20px;
}

.h24-w24-style {
  height: 24px;
  width: 24px;
}

.h30-w30-style {
  height: 30px;
  width: 30px;
}

.h20-w24-style {
  height: 20px;
  width: 24px;
}

.h24-w18-style {
  height: 24px;
  width: 18px;
}

.h22-w22-style {
  height: 22px;
  width: 22px;
}

.h12-w12-style {
  height: 12px;
  width: 12px;
}

.w12-h10-style {
  width: 12px;
  height: 10px;
}

.h70-w60-style {
  height: 70px;
  width: 60px;
}

// Font Sizes
.fs-21px {
  font-size: 21px;
}

.fs-20px {
  font-size: 20px;
}

.clickToRead-font-style {
  @include large-text(16rem, 17rem, width);
}

.termsOfUse-padding-style {
  @include large-text(0.04rem, 0.15rem, padding-top);
}

// Border-Radius
.br-5px {
  border-radius: 5px;
}

.br-5-5px {
  border-radius: 5px 0 0 5px !important;
}

.br-0-0px {
  border-radius: 0 5px 5px 0 !important;
}

// Miscelaneous
.admin-group-style {
  width: 1rem;
  height: 2px;
  background-color: #dad6d6;
  border: 1px solid #dad6d6;
}

.small-border {
  height: 16px;
  width: 2px;
  border-radius: 3px;
}

.zIndex-100 {
  z-index: 100;
}

.zIndex-10000 {
  z-index: 100000;
}

.pic-modal-dropzone {
  border: 2px dashed var(--primary);
  border-radius: 3px;
  height: 100px;
}

.gradientStyle {
  height: 12px;
  background: linear-gradient(to right, #d73838, #fcb422, #8bce00);
  margin-top: 38px;
}

.dateselect {
  padding: 1px 0px 1px 2px;
}

.multiselectable-mobile {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 300px;
}

.multiselectable {
  max-height: 300px;
  margin-left: -15px;
  margin-right: -15px;
  overflow-y: auto;
  overflow-x: hidden;
}

.custom-value-renderer {
  width: 98%;
  // ie styles
  // @media all and (-ms-high-contrast: none) {
  //   width: 93%;
  //   // margin-left: -1rem;
  // }
  line-height: 1.8;
}

.brand-style {
  width: 170px;
  position: relative;
  margin-top: 30px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.smart-logo {
  height: 25px;
  position: relative;
}

.go-live-logo {
  height: 40px;
  position: relative;
}

.patient-info-style {
  margin-top: -12px;
  margin-left: 6px;
  margin-bottom: 1rem;
}

.caution-style {
  width: 42px;
  height: 28px;
}

.checkbox-style {
  margin-left: -2rem;
  padding-right: 0.25rem;
}

.adhcell-style {
  width: 0.5rem;
  height: 0.5rem;
  display: inline-block;
  border-radius: 50%;
}

.hidden {
  visibility: hidden;
}

.med-table-style {
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.instructions-card {
  max-width: 1040px;
  box-shadow: none !important;
}

.intructions-img {
  background-size: contain;
}

.intructions-info {
  font-size: 2.3rem;
  margin-top: -1rem;
}

.details-style {
  margin-top: -1rem;
  margin-bottom: -3rem;
}

.workflow-style {
  width: 2px;
  height: 20px;
}

.gradient-style {
  height: 12px;
  background: linear-gradient(to right, #d73838, #fcb422, #8bce00);
  margin-top: 38px;
}

// padding
.padding-0-0-0-30px {
  padding: 0 0 0 30px;
}

.padding-0-0-0-42px {
  padding: 0 0 0 42px;
}

.padding-0-45-0-5px {
  padding: 0 45px 0 5px;
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}

.pointer-events-auto {
  pointer-events: auto;
}

.overflow-anywhere {
  overflow-wrap: anywhere;

  @media all and (-ms-high-contrast: none) {
    word-break: break-all;
  }
}

.no-scroll {
  height: 100%;
  overflow: hidden;
}

.custom-loader {
  display: flex;
  justify-content: center;
  margin-top: none;
  height: 100% !important;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.status-text {
  word-break: auto-phrase !important;
}

.position-fixed {
  position: fixed;
}

.flex-1 {
  flex: 1;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}

.gap-30px {
  column-gap: 30px;
}

.gap-6px {
  gap: 6px;
}

.line-height-1 {
  line-height: 1;
}

.font-size-85 {
  font-size: 85%;
}

.right-neg-4px {
  right: -4px;
}

.right-neg-10px {
  right: -10px;
}

.top-neg-10px {
  top: -10px;
}

.underline-focus:focus {
  text-decoration: underline !important;
}
