

.e-chip-list.e-chip, .e-chip-list .e-chip {
    background-color: var(--light);
    border-color: var(--graydark1);
    color: var(--dark);
}
.e-chip-list.e-chip.e-active, .e-chip-list .e-chip.e-active {
    background-color: var(--primary);
    color: white;
    border-color: var(--primary);

}

.e-chip-list.e-multi-selection .e-chip.e-active:not(.e-chip-icon-wrap):not(.e-chip-avatar-wrap)::before {
    width: 0;
}

.e-chip-list.e-chip, .e-chip-list .e-chip.big-chips {
    height: 36px;
    margin-right: 12px;
    padding: 0px 16px;
    font-size: 1rem;
    border: 2px solid var(--graydark1);
    margin-left: 0px;
}
.e-chip-list.e-selection .e-chip.e-active {
    background-color: var(--primary);
    color: white;
    border-color: var(--primary);
}
.e-chip-list:not(.e-chip) {
    padding-left: 0;
}