#startDateTime,
#endDateTime {
  color: var(--primary);
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker-wrapper input.date-time {
  border: 0;
  &.form-control {
    @include large-text(calc(100% + 5px), calc(100% + 10px), width);
  }
  @include large-text(1px 4px 1px 2px, 1px 5px 1px 2px, padding);
}

.date-time.ed-blue {
  color: var(--primary);
}

.max-width-17 {
  @include large-text(17rem, 18rem, max-width);
}

.react-datepicker__header--time {
  display: none;
}

.react-datepicker {
  border: 1px solid var(--graydark1);
  color: var(--dark);
  // border: 0;
  // box-shadow: 0 2px 5px 0 rgba(12.2, 18.4, 21.2, 0.16);
  font-family: "Effra", sans-serif;
}
.react-datepicker__triangle {
  display: none;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 100px;
}

.react-datepicker__time-container .react-datepicker__time {
  border-radius: $border-radius;
}

.react-datepicker__header {
  background-color: var(--gray);
  border-bottom: 1px solid var(--graydark1);
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: var(--dark);
}

.time-of-day-dropdown-item.dropdown-item.active {
  background-color: var(--extra-light-primary) !important;
  color: var(--dark);
  &:hover {
    color: var(--dark) !important;
  }
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: var(--extra-light-primary);
  color: var(--dark);
  &:hover {
    background-color: var(--extra-light-primary) !important;
    cursor: default !important;
  }
}

.time-of-day-dropdown-item.dropdown-item:not(.active):hover,
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background-color: var(--gray);
  color: var(--dark) !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled {
  display: none;
}
.min-width-to-fit {
  .react-datepicker {
    @include media-breakpoint-up(md) {
      min-width: 482.15px;
    }
  }
  input {
    width: 105%;
  }
}

.react-datepicker__month-container:not(:last-of-type) {
  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 0;
    border-top-left-radius: $border-radius;
  }
}
.react-datepicker__month-container:last-of-type {
  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-left-radius: 0;
    border-top-right-radius: $border-radius;
  }
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: transparent !important;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  color: var(--graydark1) !important;
}

.react-datepicker__close-icon::after {
  background-color: var(--primary);
}
.dose-box {
  .react-datepicker__input-container {
    width: auto;
  }
}

.react-datepicker-wrapper input.bg-gray::placeholder {
  color: var(--primary);
}

.time-dropdown {
  @include large-text(0.875rem, 1rem, font-size);
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: 114px;
    text-align: start;
  }

  .react-datepicker {
    border: none;
  }
  .dropdown-header {
    font-weight: medium;
    color: var(--dark);
  }
}

.react-datepicker__current-month {
  @include large-text(0.875rem, 1rem, font-size);
}
