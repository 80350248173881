div,
li,
button,
h6,
a {
  &.primary-underline {
    position: relative;
    display: inline-block;
    &:not(:last-child):after {
      content: "";
      position: absolute;
      // z-index: -1;
      left: 0;
      right: 0;
      bottom: 0;
      height: 0;
      border: 1px solid $border-color;
      background: $border-color;
      border-radius: 5px;
    }
    &:after {
      content: "";
      position: absolute;
      // z-index: 1;
      left: 0;
      right: 0;
      bottom: 0;
      height: 0;
      border: 1px solid $border-color;
      background: $border-color;
      border-radius: 5px;
    }
    &.text-primary {
      &:after {
        border: 1px solid var(--primary);
        background: var(--primary);
        bottom: -0.5rem;
      }
    }
  }
}

div.left-border {
  @include media-breakpoint-up(sm) {
    position: relative;
    display: inline-block;
    z-index: 0;
    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 1rem;
      left: -0.25rem;
      bottom: 1rem;
      // height: 0;
      width: 2px;
      background: $border-color;
      border-radius: 5px;
    }
  }
}

.dashed-border {
  border-style: dashed;
  border-color: var(--graydark1);
}

.alert-border {
  border: 2px solid $yellow !important;
}

.border-left.dashed {
  border-left: 2px dashed var(--graydark1) !important;
}

.border-right.dashed {
  border-right: 2px dashed var(--graydark1) !important;
}

.rounded {
  border-radius: $border-radius;
}
