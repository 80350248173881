.pagination.circle-pagination {
  .page-item:first-child:not(.paginate_button),
  .page-item:last-child:not(.paginate_button),
  .page-item:not(.paginate_button) {
    .btn-link {
      color: white;
      display: inline-block;
      margin: 0px 2.3rem;
      padding: 0px 7.5px;
      border-radius: 50%;

      &:focus {
        box-shadow: none;
      }
      &.active {
        border: 3px solid white;
        color: white;
      }
      &:hover {
        color: white;
        background-color: transparent;
      }
    }
  }
  .page-item:not(:last-child):not(.paginate_button) > .btn-link {
    &:after {
      content: "";
      position: absolute;
      z-index: 1;
      left: 1.7rem;
      right: 0;
      bottom: 50%;
      height: 0;
      border: 1px solid white;
      background: white;
      border-radius: 5px;
      width: 4.5rem;
    }
  }
}

.smart-container {
  @include media-breakpoint-down(mdlg) {
    padding-top: 2rem;
  }
}

.smart-container .pagination {
  // .page-item:not(.paginate_button),
  // .page-item:last-child:not(.paginate_button),
  .page-item:not(.paginate_button) {
    .page-link {
      @include make-accessible(var(--dark), var(--dark-dark), color);
      display: inline-block;
      margin: 0px 2.3rem;
      padding: 0px 7.5px;
      border-radius: 50%;

      &:focus {
        box-shadow: none;
      }
      &.active {
        @include make-accessible(
          3px solid var(--primary),
          3px solid var(--dark-primary),
          border
        );
        @include make-accessible(var(--primary), var(--dark-primay), color);
      }
      &:hover {
        @include make-accessible(var(--primary), var(--dark-primay), color);
        background-color: transparent;
      }
    }
  }
  .page-item:not(:last-child):not(.paginate_button) > .page-link {
    &:after {
      content: "";
      position: absolute;
      z-index: 1;
      left: 1.7rem;
      right: 0;
      bottom: 50%;
      height: 0;
      border: 1px solid var(--graydark1);
      background: var(--graydark1);
      border-radius: 5px;
      width: 4.5rem;
    }
  }
}

.import-circles {
  pointer-events: none;
}

//disable clicking on ellipsis between pages
li.break-me {
  pointer-events: none;
  cursor: default;
}

.e-pager {
  background-color: transparent;
  border: 0;
  width: auto;
  div.e-icons.e-pager-default,
  div.e-icons.e-icon-prev,
  div.e-icons.e-icon-first {
    color: $dark;
    font-size: 0.875rem;
    // padding-top: 1rem;
    &:hover {
      color: var(--secondary);
      background-color: var(--bg);
    }
    &.e-disable {
      color: var(--info);
      &:hover {
        color: var(--info);
      }
    }
    @media only screen and (max-width: 769px) {
      display: none;
    }
  }
}

.e-pager .e-pagercontainer {
  background-color: transparent;
  border: 0;
  display: inline !important;
}

.e-pager div.e-parentmsgbar {
  display: none;
}

.e-pager .e-numericitem {
  background: transparent;
  color: $dark;
  border: 2px solid $dark;
  font-size: 1rem;

  padding: 6px;
  &:not(.e-pp):not(.e-np) {
    &[index="1"],
    &[index="2"],
    &[index="3"],
    &[index="4"],
    &[index="5"],
    &[index="6"],
    &[index="7"],
    &[index="8"],
    &[index="9"] {
      padding: 6px 10px;
    }
  }
  margin: 8px;
  &.e-active {
    color: white;
    background: $dark;
    &:hover {
      padding: 6px;
      margin: 8px;
      cursor: default;
      &:not(.e-pp):not(.e-np) {
        &[index="1"],
        &[index="2"],
        &[index="3"],
        &[index="4"],
        &[index="5"],
        &[index="6"],
        &[index="7"],
        &[index="8"],
        &[index="9"] {
          padding: 6px 10px;
        }
      }
    }
  }
  // background-color: transparent;
  // border: $dark;
}

.e-pager .e-spacing,
.e-pager .e-numericitem:hover,
.e-pager .e-currentitem {
  padding: 6px;
  margin: 8px;
  &:hover {
    background-color: $dark !important;
    color: white;
  }
}

.e-pager .e-numericitem:not(.e-active):not(.e-np):not(.e-pp):hover {
  background-color: $dark !important;
  color: white;
  border: 2px solid $dark;
  font-size: 1rem;

  padding: 6px;
  margin: 8px;
  &:not(.e-pp):not(.e-np) {
    &[index="1"],
    &[index="2"],
    &[index="3"],
    &[index="4"],
    &[index="5"],
    &[index="6"],
    &[index="7"],
    &[index="8"],
    &[index="9"] {
      padding: 6px 10px;
    }
  }
}

.e-pager .e-prevpagedisabled,
.e-pager .e-prevpage,
.e-pager .e-nextpage,
.e-pager .e-nextpagedisabled,
.e-pager .e-lastpagedisabled,
.e-pager .e-lastpage,
.e-pager .e-firstpage,
.e-pager .e-firstpagedisabled {
  background: transparent;
  color: $dark;
  border: 0 !important;
  margin: 8px;
}

.e-pager .e-pagercontainer .e-firstpage,
.e-pager .e-pagercontainer .e-prevpage,
.e-pager .e-pagercontainer .e-firstpagedisabled,
.e-pager .e-pagercontainer .e-prevpagedisabled,
.e-pager .e-pagercontainer .e-nextpage,
.e-pager .e-pagercontainer .e-lastpage,
.e-pager .e-pagercontainer .e-nextpagedisabled,
.e-pager .e-pagercontainer .e-lastpagedisabled {
  padding: 5px;
  font-size: 0.875rem;
}

.e-pager .e-currentitem.e-numericitem.e-focused {
  background: var(--dark);
}
