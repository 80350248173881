@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

@import "../../node_modules/@syncfusion/ej2-base/styles/bootstrap.css";
@import "../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap.css";
@import "../../node_modules/@syncfusion/ej2-lists/styles/bootstrap.css";
@import "../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap.css";
@import "../../node_modules/@syncfusion/ej2-popups/styles/bootstrap.css";
@import "../../node_modules/@syncfusion/ej2-react-grids/styles/bootstrap.css";
@import "../../node_modules/@syncfusion/ej2-layouts/styles/bootstrap.css";
@import "../../node_modules/@syncfusion/ej2-react-navigations/styles/bootstrap.css";
@import "../../node_modules/@syncfusion/ej2-react-buttons/styles/bootstrap.css";
@import "../../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap.css";
@import "../../node_modules/@syncfusion/ej2-richtexteditor/styles/bootstrap.css";
@import "../../node_modules/@syncfusion/ej2-treegrid/styles/bootstrap.css";
@import "../../node_modules/@syncfusion/ej2-navigations/styles/bootstrap.css";
@import "../../node_modules/@syncfusion/ej2-grids/styles/bootstrap.css";
@import "../../node_modules/@syncfusion/ej2-gantt/styles/bootstrap.css";
@import "../../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap.css";
@import "../../node_modules/@syncfusion/ej2-calendars/styles/bootstrap.css";

@import "./variables/custom-variables.scss";
@import "./mixins/accessibility.scss";

@import "../../node_modules/bootstrap/scss/bootstrap";
@import "base/siteRules";
@import "base/typography";
@import "base/helpers";
@import "base/inline-style";

@import "layout/forms";
@import "layout/grid";
@import "layout/header";
@import "layout/navigation";
@import "layout/sidebar";
@import "layout/ph";

@import "components/alerts";
@import "components/avatar";
@import "components/badges";
@import "components/borders";
@import "components/buttons";
@import "components/cards";
@import "components/carousel";
@import "components/charts";
@import "components/checkboxes";
@import "components/dateRangePicker";
@import "components/dropdowns";
@import "components/glyphs";
@import "components/modals";
@import "components/pagination";
@import "components/progress";
@import "components/tables";
@import "components/tooltips";
@import "components/calendar";
@import "components/leaflets";
@import "components/chips";
