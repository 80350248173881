// .fc-view-harness {
//   min-height: 50vh;
// }

.fc-v-event.missed,
.fc-v-event.taken,
.fc-v-event.skipped,
.fc-h-event {
  .fc-event-main {
    @include make-accessible(
      $navy !important,
      var(--dark-dark) !important,
      color
    );
    font-weight: 500;
  }
}

.fc-v-event.missed,
.fc-v-event.taken,
.fc-v-event.skipped {
  svg path,
  svg circle {
    @include make-accessible(
      $navy !important,
      var(--dark-dark) !important,
      stroke
    );
  }
}

.fc-h-event.fc-event-all-meds {
  background-color: var(--extra-light-primary);
  border: 2px solid var(--extra-light-primary);

  &:hover {
    border: 2px solid var(--dark-primary);
  }

  &:focus {
    border: 2px solid var(--dark-primary);
  }

  @include media-breakpoint-down(sm) {
    border-radius: 50%;
    width: 0.75rem;
    height: 0.75rem;
    background-color: var(--dark-success);
    border: 10px solid white;

    &:hover {
      border: 10px solid white;
    }
  }
}

.fc-h-event.fc-event-no-meds {
  background-color: $red-light1;
  border: 2px solid $red-light1;

  &:hover {
    border: 2px solid #d73838;
  }

  &:focus {
    border: 2px solid #d73838;
  }

  @include media-breakpoint-down(sm) {
    border-radius: 50%;
    width: 0.75rem;
    height: 0.75rem;
    background-color: var(--danger);
    border: 10px solid white;

    &:hover {
      border: 10px solid white;
    }
  }
}

.fc-h-event.fc-event-partial-meds {
  background-color: var(--gray);
  border: 2px solid var(--gray);

  &:hover {
    border: 2px solid #8c8c8c;
  }

  &:focus {
    border: 2px solid #8c8c8c;
  }

  @include media-breakpoint-down(sm) {
    border-radius: 50%;
    width: 0.75rem;
    height: 0.75rem;
    background-color: #e3e1e2;

    border: 10px solid white;

    &:hover {
      border: 10px solid white;
    }
  }
}

.fc-day-today {
  .fc-h-event.fc-event-no-meds,
  .fc-h-event.fc-event-all-meds,
  .fc-h-event.fc-event-partial-meds {
    @include media-breakpoint-down(sm) {
      border-color: var(--light);
    }
  }
}

.fc {
  .fc-h-event.fc-event-edit-meds {
    background-color: transparent !important;
    border: none !important;

    .fc-event-main {
      @include make-accessible(
        var(--graydark2) !important,
        var(--secondary) !important,
        color
      );
    }

    svg path {
      @include make-accessible(
        var(--graydark2) !important,
        var(--secondary) !important,
        stroke
      );
    }
  }

  .fc-event-main:focus {
    color: $dark !important;

    svg path {
      stroke: $dark !important;
    }
  }

  .fc-prev-button,
  .fc-next-button {
    @include media-breakpoint-down(sm) {
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin-right: 10px;
    }
  }

  .fc-button-primary.fc-timeGridWeek-button,
  .fc-button-primary.fc-dayGridMonth-button {
    @include make-accessible(var(--dark), var(--dark-dark), color);
    width: 6rem;
    @include large-text(1rem, 1.125rem, font-size);
    white-space: nowrap;

    background-color: white;
    @include make-accessible(
      2px solid var(--dark),
      2px solid var(--dark-dark),
      border
    );

    &.fc-button-active,
    &.fc-button:hover {
      color: white;
      @include make-accessible(var(--dark), var(--dark-dark), background-color);
    }

    &.fc-button:focus {
      background-color: var(--dark-dark);
      color: white;
      // border-color: var(--graydark2);
    }
  }

  .fc-scrollgrid-section-body.fc-scrollgrid-section-liquid,
  .fc-scrollgrid.fc-scrollgrid-liquid,
  .fc-scrollgrid-sync-table,
  .fc-theme-standard td,
  .fc-theme-standard th,
  .fc-timegrid-axis {
    border: none;
  }

  .fc-button-primary.fc-prev-button:not(:disabled),
  .fc-button-primary.fc-next-button:not(:disabled) {
    @include make-accessible(var(--dark), var(--dark-dark), color);
    background-color: white;
    border: none;

    &:hover,
    &:focus,
    &:active {
      background-color: white;
      color: var(--secondary);
      border: none;
    }

    // border: 2px solid var(--dark);
  }

  .fc-todayButton-button.fc-button.fc-button-primary {
    background-color: white !important;
    border: 2px solid var(--graydark1) !important;
    @include large-text(1.125rem, 1.25rem, font-size);

    color: var(--secondary) !important;
    padding: 0.25rem 2.5rem;
    margin-left: 10px;
    margin-right: 10px;
    text-transform: capitalize;

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      background-color: var(--graydark1) !important;
      // color: white !important;
    }

    &:disabled {
      color: var(--graydark1) !important;
      border: 2px solid $border-color !important;
    }
  }
}

.width-275 {
  .popover {
    max-width: 600px !important;
    min-width: 400px !important;

    @include media-breakpoint-down(sm) {
      min-width: 0 !important;
      max-width: 100vw !important;
    }

    .popover-body {
      padding: 1rem 1.5rem;
    }

    time,
    .no-wrap {
      white-space: nowrap;
    }
  }
}

.width-220 {
  .popover {
    width: 5%;
  }
}

.fc-timegrid-event {
  padding: 0.2rem;
  cursor: pointer;
  width: 100%;

  &:focus {
    color: $dark;
  }
}

.fc-daygrid-event {
  margin: 0 0.5rem;
  padding: 0.25rem;
  cursor: pointer;
}

.fc .fc-scrollgrid-section > td {
  border: 1px solid var(--gray);
}

//Numbers on calendar
.fc-daygrid-day-top {
  .fc-daygrid-day-number {
    color: var(--secondary);
    @include large-text(1rem, 1.125rem, font-size);
  }

  float: left !important;
}

//Today light gray background
.fc .fc-daygrid-day.fc-day-today,
.fc .fc-timegrid-col.fc-day.fc-day-today {
  background-color: var(--light) !important;
}

// .fc-timegrid-axis,
// .fc-daygrid-axis {
//   @include large-text(1rem, 1.125rem, font-size);
//   border: none !important;
// }

// Getting rid of scrollbar on header
.fc-scrollgrid-section-header .fc-scroller {
  overflow: hidden !important;
}

//Days of week names
.fc-theme-standard th.fc-col-header-cell {
  border: 0;
  background-color: white;

  .fc-scrollgrid-sync-inner {
    text-align: left;

    .fc-col-header-cell-cushion {
      color: var(--secondary);
    }
  }
}

.fc-toolbar-title {
  @include media-breakpoint-down(sm) {
    font-size: 25px !important;
    margin-left: 1rem;
  }

  vertical-align: bottom;

  margin-bottom: 0;
}

// .fc .fc-col-header {
//   border: 0;
//   margin-bottom: 0.5rem;
//   text-align: left;
//   @include large-text(1rem, 1.125rem, font-size);
//
//   color: var(--secondary);
// }

.fc-button:focus,
.fc-button-primary:not(:disabled).fc-button-active:focus,
.fc-button-primary:not(:disabled):active:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.fc-button,
.fc-icon {
  font-weight: 700;
}

// @supports not (-ms-high-contrast: none) {
//   .fc {
//     .fc-icon-chevron-left {
//       &::before {
//         content: url("../../images/chevron_left.svg");
//       }
//       &:hover::before {
//         content: url("../../images/chevron_left_gray.svg");
//       }
//       &:focus::before {
//         content: url("../../images/chevron_left_gray.svg");
//       }
//     }
//     .fc-prev-button:focus {
//       .fc-icon-chevron-left::before {
//         content: url("../../images/chevron_left_gray.svg");
//       }
//     }
//     .fc-icon-chevron-right {
//       &::before {
//         content: url("../../images/chevron_right.svg");
//       }
//       &:hover::before,
//       &:focus::before {
//         content: url("../../images/chevron_right_gray.svg");
//       }
//     }
//     .fc-next-button:focus {
//       .fc-icon-chevron-right::before {
//         content: url("../../images/chevron_right_gray.svg");
//       }
//     }
//   }
// }
.taken.fc-timegrid-event {
  border-color: #ffffff;
  background-color: var(--extra-light-primary);
  position: relative;
  display: inline-block;
  z-index: 0;

  .fc-event-title-container {
    background-image: url("../../images/glyph-circle-check.png");

    background-position-y: 50%;
    background-repeat: no-repeat;
  }

  .fc-event-title {
    margin-left: 18px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
  }

  &:focus {
    background-color: var(--primary);
  }
}

.missed.fc-timegrid-event {
  border-color: #ffffff;
  background-color: $red-light1;
  position: relative;
  display: inline-block;
  z-index: 0;
  // &:before {

  // }
  .fc-event-title-container {
    background-position-y: 50%;
    background-image: url("../../images/glyph-circle-x.png");
    background-repeat: no-repeat;
  }

  .fc-event-title {
    margin-left: 18px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
  }

  &:focus {
    background-color: var(--danger);
  }
}

.skipped.fc-timegrid-event {
  border-color: #ffffff;
  background-color: $yellow-light1;
  position: relative;
  display: inline-block;
  z-index: 0;

  .fc-event-title-container {
    background-position-y: 50%;
    background-image: url("../../images/glyph-circle-skip.png");
    background-repeat: no-repeat;
  }

  .fc-event-title {
    margin-left: 18px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
  }

  &:focus {
    @include make-accessible(
      var(--warning),
      var(--dark-warning),
      background-color
    );
  }
}

// .med-added .fc-timegrid-event {
//   position: relative;
//   display: inline-block;
//   z-index: 0;
//   .fc-event-title-container {
//     background-image: url("../../images/glyph-plus-med.svg");
//     background-repeat: no-repeat;
//   }

//   .fc-event-title {
//     margin-left: 18px;
//     width: 100%;
//     white-space: nowrap;
//     overflow: hidden;
//   }
//   &:focus {
//     color: $dark;
//   }
// }

// .fc-event-title {
//   font-weight: 500;
//   @include large-text(1rem, 1.125rem, font-size);
// }

// .fc-timegrid-event .fc-event-time {
//   display: none !important;
// }

// .fc-col-header-cell.fc-day,

td.fc-daygrid-day:not(.fc-day-disabled),
.fc-timegrid-slot.fc-timegrid-slot-lane {
  border: 2px solid var(--gray);
  border-radius: 5px;
}

.fc-timegrid-slot,
.fc-timegrid-axis-cushion {
  @include make-accessible(var(--graydark2), var(--secondary), color);

  @include large-text(0.875rem !important, 1rem !important, font-size);
}

.fc .fc-scrollgrid table {
  border-style: none !important;
  border-style: unset;
}

.fc .fc-scrollgrid thead {
  background-color: #ffffff;
}

.fc .fc-scrollgrid thead tr th {
  background-color: #ffffff;
}

.fc .fc-timegrid-slot-minor {
  border-top-style: hidden;
}

//calendar disappeared so need to remove height auto here
.fc .fc-scrollgrid-section-liquid {
  height: 1px;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 1rem;
}

.fc h2 {
  display: inline;
}

#popover div div:last-child {
  &:after {
    border: 1px solid transparent !important;
    background: transparent !important;
  }

  margin-bottom: 0.5rem !important;
}

.fc .fc-day-disabled {
  background: white !important;
  border: none;
}

.fc td[role="presentation"] {
  border: 0;
}

.fc a {
  color: var(--secondary);
}

.fc-timegrid-col.fc-day {
  border: 2px solid var(--gray);
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  max-width: 100%;
  overflow: hidden;
  margin-right: 0.5rem;
}

.popover .close {
  color: white;
  text-shadow: none;
  font-size: 1.5rem;
  font-weight: normal;
  cursor: pointer;

  &:hover,
  &:focus {
    color: var(--graydark1);
  }
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start,
.fc-direction-rtl .fc-daygrid-event.fc-event-end {
  margin-left: 0.5rem;
}

.fc-col-header,
.fc-daygrid-body,
.fc-scrollgrid-sync-table {
  width: 100% !important;
}

.fc-customFilter-button.fc-button,
.fc-customHeader-button.fc-button {
  background-color: transparent !important;
  border: 0 !important;
  width: 11rem;
}

#medSelectFilter {
  // min-width: 15rem;
  padding-left: 0.5rem;
  text-align: left;

  &:hover {
    .glyph-link-blue path {
      stroke: #8c8c8c;
    }

    span.text-link {
      color: #8c8c8c !important;
    }
  }
}

@include media-breakpoint-down(sm) {
  .fc-daygrid-day-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 30px !important;
  }

  .fc-daygrid-day {
    border: 0 !important;
  }

  .fc-daygrid-day-number {
    color: $navy !important;
  }

  .fc-daygrid-event-harness {
    display: flex;
    justify-content: center;
  }

  .fc-day-today {
    background-color: transparent !important;

    .fc-daygrid-day-frame:before {
      content: "";
      position: absolute;
      z-index: -1;
      width: 2rem;
      height: 2rem;
      background-color: #edeeef;
      border-radius: 50%;
      border: 5px solid #edeeef;

      display: block;
    }

    .fc-daygrid-day-number {
      color: var(--primary) !important;
      font-weight: bold !important;
      font-size: 18px;
    }
  }
}
.fc .fc-button {
  padding: 0.2rem 0.65rem;
}
.fc .fc-prev-button,
.fc .fc-next-button {
  margin-left: 1px !important;
  margin-right: 8px !important;
}

.fc-event-main {
  white-space: nowrap;
  overflow: hidden;
}

.fc-list-event-graphic,
.fc-list-event-time {
  display: none;
}

.fc .fc-list-event:hover td {
  background-color: transparent !important;
}

table.fc-list-table .fc-list-day {
  display: none;

  th {
    border: 0;
  }

  .fc-list-day-text {
    color: $dark;
  }
}

.fc-theme-standard .fc-list {
  border: 0 !important;
}

.fc .fc-list-table td {
  padding: 0px 1.5rem 0px !important;
}

.fc .fc-list-day-cushion {
  padding: 8px 1.5rem !important;
}

div.custom-event:not(:last-of-type) {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0;
    border: 1px solid var(--light);
    background: var(--light);
    border-radius: 5px;
  }
}

div.today-header {
  position: relative;
  margin-top: -2px;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: -1.5rem;

    bottom: 0;
    top: 0;
    width: 1.5rem;
    background: var(--light);
  }

  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    right: -1.5rem;

    bottom: 0;
    top: 0;
    width: 1.5rem;
    background: var(--light);
  }
}

.fc .fc-popover {
  z-index: 100;
}

.fc-scrollgrid-section.fc-scrollgrid-section-header th {
  border-right: 0;
}
