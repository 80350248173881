.cntnr .box,
.loginContainer {
  min-height: 85vh;
  padding-top: 5rem;
  width: calc(100vw - 482px);
  @include media-breakpoint-only(md) {
    width: calc(100vw - 369px);
  }
  @include media-breakpoint-only(mdlg) {
    width: calc(100vw - 369px);
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
    height: 100%;
    padding-top: 3rem;
  }
  padding-bottom: 3rem;
}
.cntnr {
  // width: 100%;
  @include media-breakpoint-down(sm) {
    padding-left: 0;
    padding-right: 0;
    // max-width: 767px;
  }
}
#access-denied {
  height: 80vh;
  padding-top: 100px;
}

@include media-breakpoint-down(mdlg) {
  .col-12:not(.form-group):not(.medium) {
    padding-left: 0;
    padding-right: 0;
    overflow-x: hidden;
  }
}

@viewport {
  width: auto !important;
}

.col-xl-3half {
  @extend .col-xl;
  @include media-breakpoint-up(xl) {
    flex: 0 0 29.1667%;
    max-width: 29.1667%;
  }
}

#login-container {
  // background-image: url("../../images/Flow9bg.png");

  @include make-accessible(var(--dark), var(--dark-dark), background-color);
  min-height: 100vh;
  #gradient-container {
    background-size: cover;
    @include media-breakpoint-only(md) {
      background-image: url("../../images/login-gradient-tablet.png");
      width: 369px;
    }
    @include media-breakpoint-only(mdlg) {
      background-image: url("../../images/login-gradient-tablet.png");
      width: 369px;
    }
    @include media-breakpoint-up(lg) {
      background-image: url("../../images/login-gradient-desktop.png");
      width: 482px;
    }
  }
  #login-logo {
    @include media-breakpoint-down(sm) {
      width: 175px;
      margin-left: -5px;
    }
    @include media-breakpoint-up(md) {
      width: 190px;
    }
  }
  form {
    @include media-breakpoint-down(sm) {
      height: 60vh;
    }
  }
}

.w-40 {
  width: 40%;
}
