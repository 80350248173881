//EveryDose Blue
$ed-blue: #1da1f2;
$strong-blue: #1d37f2;
$ed-blue-light1: #4fc0e7;
$ed-blue-dark1: #0097c8;
$ed-blue-dark2: #0078c8;
$ed-blue-dark3: #005e83;
$ed-blue-dark4: #083f6a;
$new-blue: #035f84;

//Navy
$navy: #1f2f36;
$navy-light4: #a5acaf;
$navy-light3: #798286;
$navy-light2: #4a616a;
$navy-light1: #314852;
$navy-dark: #0a1b22;

//Turquoise
$turquoise: #5cc875;

//Green
$green-light1: #a9e405;
$green-light2: #c6f843;
$green: #65aa00;
$green-dark1: #328800;

//Red
$red: #d73838;
$red-light1: #ff8d8d;
$red-light3: #ffe4e4;
$red-dark1: #b41b1b;
$red-dark2: #850000;

//Yellow
$yellow: #fcb422;
$yellow-light1: #ffd276;
$yellow-dark1: #d59004;
$yellow-dark2: #a77100;

//Orange
$orange: #e9762d;

//Neutral
$gray-light2: #f8f8f8;
$gray-light1: #f2f2f2;
$gray: #edeeef;
$gray-dark1: #dad6d6;
$gray-dark2: #8c8c8c;
$gray-dark3: #747474;
$steel: #e3ecee;
$background: #f2f2f7;

$theme-colors: (
  "primary": $ed-blue-dark2,
  "light-primary": $ed-blue,
  "extra-light-primary": $ed-blue-light1,
  "dark-primary": $ed-blue-dark4,
  "danger": $red,
  "dark-danger": $red-dark1,
  "warning": $yellow,
  "dark-warning": $yellow-dark1,
  "success": $green,
  "dark-success": $green-dark1,
  "info": $navy-light4,
  "secondary": $gray-dark3,
  "dark": $navy,
  "dark-dark": $navy-dark,
  "light": $gray-light1,
  "gray": $gray,
  "badge-gray": hsl(0, 0%, 90%),
  "graydark1": $gray-dark1,
  "graydark2": $gray-dark2,
  "white": white,
  "strong": $strong-blue,
  "bg": $background,
  "purple": #6d2f99
);

$body-bg: var(--bg);
$body-color: var(--dark);
$border-color: var(--gray);
$table-border-color: $border-color;

$border-width: 2px;

$font-size-base: 1rem;
$font-weight-normal: 400;
$font-weight-base: $font-weight-normal;
$h1-font-size: 3.4375rem;
$h2-font-size: 2rem;
$h3-font-size: 1.5625rem;
$h4-font-size: 1.3125rem;
$h5-font-size: 1.125rem;
$h6-font-size: $font-size-base;
$small-font-size: 0.875rem;
$headings-color: var(--dark);
$headings-margin-bottom: 0.25rem;
$headings-font-weight: 500;
$line-height-base: 1.4;

$border-radius: 5px;
$btn-border-radius: 4.1px;
$box-shadow: 0 2px 1px rgba(#bfbfbf, 0.33);
$input-border-color: $border-color;

$font-family-base: "Effra", sans-serif !important;

$nav-tabs-border-color: transparent;

$nav-tabs-border-width: $border-width;
$nav-tabs-link-active-bg: white;
$nav-tabs-link-active-color: var(--primary);
$nav-tabs-link-active-border-color: transparent;
$nav-tabs-link-hover-border-color: transparent;
$card-spacer-y: 0.5rem;
$card-spacer-x: 1.5rem;
$card-bg: $body-bg;
$card-border-color: $body-bg;
$card-border-width: $border-width;
$link-color: var(--primary);
$link-hover-color: var(--graydark2);

$breadcrumb-bg: transparent;
$lead-font-size: $font-size-base * 2;

$table-cell-padding-sm: 0.2rem;

$badge-border-radius: $border-radius;

$grid-breakpoints: (
  xs: 0,
  sm: 540px,
  md: 768px,
  mdlg: 992px,
  lg: 1280px,
  xl: 1440px,
  xxl: 1600px,
  xxxl: 1800px
);

$modal-xl: 1125px;
$modal-lg: 893px;
$modal-md: 700px;
$modal-sm: 500px;

$modal-backdrop-bg: var(--dark);
$modal-backdrop-opacity: 0.3;

$nav-pills-link-active-bg: var(--primary);

$spacer: 1rem;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25
    ),
    2: (
      $spacer * 0.5
    ),
    3: $spacer * 0.75,
    4: (
      $spacer
    ),
    5: (
      $spacer * 1.5
    ),
    6: (
      $spacer * 2.1875
    ),
    7: (
      $spacer * 3
    )
  ),
  $spacers
);

$sizes: ();
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    90: 90%,
    100: 100%,
    auto: auto
  ),
  $sizes
);

$close-color: var(--dark);
$close-font-size: $font-size-base * 2;

$btn-font-size: 0.875rem;
$modal-content-border-radius: $border-radius;
$modal-content-border-width: $border-width;
$modal-header-border-width: $modal-content-border-width;
$modal-header-border-color: $border-color;

$popover-header-bg: $navy;
$popover-header-color: $gray-light2;
$popover-arrow-width: 0;
$popover-arrow-height: 0;
$popover-border-width: 0;

$popover-body-padding-x: 35px;
$popover-body-padding-y: 1rem;
$popover-font-size: 0.875rem;

$btn-border-width: $border-width;
$input-placeholder-color: var(--graydark2);

$tooltip-bg: white;
$tooltip-arrow-width: 0;
$tooltip-arrow-height: 0;
$tooltip-padding-x: 1.5rem;
$tooltip-color: var(--dark);
$tooltip-opacity: 1;
$tooltip-padding-y: 0.75rem;

//Pagination
$pagination-border-color: transparent;
$pagination-color: var(--dark);
$pagination-bg: transparent;
$pagination-border-width: 3px;
$pagination-active-color: var(--primary);
$pagination-active-bg: transparent;
$pagination-active-border-color: var(--primary);
$pagination-line-height: 0.75rem;
$pagination-hover-color: var(--primary);
$pagination-hover-bg: $body-bg;
$pagination-hover-border-color: $border-color;

$table-head-bg: var(--gray);
$grid-gutter-width: 35px;
$table-border-width: 2px;
$input-font-family: "Effra", sans-serif;
